import React, { useState } from "react";

const CustomPagination = (props) => {
    const { totalCount, pagination, setPagination, limitList, setLimitList } =
        props;

    const totalPages = Math.ceil(totalCount / limitList);
    const [inputValue, setInputValue] = useState(pagination); // Temporary state for input

    // Handle input change for pagination number
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value); // Update temporary input state
    };

    // Handle Enter key for input submission
    const handleInputSubmit = (e) => {
        if (e.key === "Enter") {
            const value = parseInt(inputValue, 10);
            if (!isNaN(value) && value >= 1 && value <= totalPages) {
                setPagination(value); // Update pagination on valid input
            } else {
                setInputValue(pagination); // Reset to current page on invalid input
            }
        }
    };

    // Handle select change for limit list
    const handleLimitChange = (e) => {
        const newLimit = parseInt(e.target.value, 10);
        setLimitList(newLimit); // Update items per page
        setPagination(1); // Reset to the first page
        setInputValue(1);
    };

    const goToPreviousPage = () => {
        if (pagination > 1) {
            setPagination(pagination - 1);
            setInputValue(pagination - 1);
        }
    };

    const goToNextPage = () => {
        if (pagination < totalPages) {
            setPagination(pagination + 1);
            setInputValue(pagination + 1);
        }
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    marginBottom: "20px",
                }}
            >
                <div>
                    <label htmlFor="itemsPerPage"></label>
                    <select
                        id="itemsPerPage"
                        value={limitList}
                        onChange={handleLimitChange}
                        style={{
                            padding: "5px",
                            border: "1px solid #ced4da",
                            outline: "none",
                            borderRadius: "4px",
                            marginRight: "20px"
                        }}
                    >
                        <option value={5}>5 / Page</option>
                        <option value={10}>10 / Page</option>
                        <option value={20}>20 / Page</option>
                        <option value={50}>50 / Page</option>
                        <option value={100}>100 / Page</option>
                    </select>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <button
                        className="btn btn-sm paginationCustome"
                        onClick={goToPreviousPage}
                        disabled={pagination <= 1}
                    >
                        &laquo;
                    </button>
                    <span>
                        {" "}
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputSubmit}
                            style={{
                                width: "50px",
                                textAlign: "center",
                                margin: "0 5px",
                                border: "1px solid #ced4da",
                                borderRadius: "4px",
                                outline: "none",
                                padding: "2px",
                            }}
                        />{" "}
                        of {totalPages}
                    </span>
                    <button
                        className="btn btn-sm paginationCustome"
                        onClick={goToNextPage}
                        disabled={pagination >= totalPages}
                    >
                        &raquo;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomPagination;
