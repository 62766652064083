import React, { useEffect, useRef, useState } from 'react'
import FilterIcon from '../assets/images/filter-icon.svg'
import CloseIcon from '../assets/images/close-icon.svg'
import DropdownIcon from '../assets/images/dropdown-icon.svg'
import CalendarIcon from '../assets/images/calendar-icon.svg'
import { CalendarCSL } from "../container/calendar/CalendarCSL";
import { formatDateAPI } from "../functions/format/dateFormat";
import { BiSearch } from 'react-icons/bi'
import "../styles/searchFilterHeader.css"
import { dataSubmission, dataSearch, dataRequestType } from '../container/FixedData/fixedData'

const SearchFilterHeaderDraft = (props) => {

    const {
        setSearch,
        search,
        fetchUserFormList,
        arrStatus,
        dataDontationHistory,
        setdataDontationHistory,
        setBeforeDate,
        setAfterDate,
        setFromDate,
        setToDate,
        beforeDate,
        afterDate,
        fromDate,
        toDate,
        setSubmissionValue,
        submissionValue,
        setPagination,
        setclear,
        requestType,
        setRequestType
    } = props;

    const [filterToggle, setFilterToggle] = useState(false)
    const [submissionToggle, setSubmissionToggle] = useState(false)
    const [beforeActive, setBeforeActive] = useState(false)
    const [afterActive, setAfterActive] = useState(false)
    const [toActive, setToActive] = useState(false)
    const [fromActive, setFromActive] = useState(false)
    const [typeActive, setTypeActive] = useState(false)

    useEffect(() => {
        document.addEventListener("click", handleClickOutSide, true)
        return () => {
            document.removeEventListener('click', handleClickOutSide);
          };
    }, [])

    const refOne = useRef(null)

    const handleClickOutSide = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setBeforeActive(false)
            setAfterActive(false)
            setToActive(false)
            setFromActive(false)
        }
    }

    const handleSearchWebform = () => {
        setPagination(1)
        fetchUserFormList()
    }

    const changeDateAfter = (date) => {
        setAfterDate(date)
    }

    const changeDateBefore = (date) => {
        setBeforeDate(date)
    }
    const changeDateTo = (date) => {
        setToDate(date)
    }
    const changeDateFrom = (date) => {
        setFromDate(date)
    }

    const handleFilterWebForm = () => {
        setPagination(1)
        if ((fromDate && toDate) || afterDate || beforeDate || requestType || arrStatus.length > 0) {
            fetchUserFormList()
        } else {
            return false
        }
    }

    const handleProgressStatus = (event, status, i) => {
        dataDontationHistory[i]['isCheck'] = event.target.checked;
        setdataDontationHistory([
            ...dataDontationHistory,
        ])
    }

    const handleClearFilter = () => {
        setSearch("")
        setPagination(1)
        let items = dataDontationHistory.map(it => ({
            ...it,
            isCheck: false
        }))
        setdataDontationHistory([
            ...items,
        ])
        setBeforeDate("")
        setAfterDate("")
        setToDate("")
        setFromDate("")
        setSubmissionValue("")
        setRequestType("")
        setclear("clear")
    }
    const handleKeyEnter = (e) => {
        if (e.key === 'Enter') {
            handleSearchWebform()
        }
    };

    return (
        <div className="row">
            <div className="col-12 col-md-1 col-sm-12">
                <label htmlFor="basic-url" className="infoLabel mt-1 size14700">Search</label>
            </div>
            <div className="col-12 col-md-10 col-sm-12 mt-md-0 mt-3">
                <input
                    style={{ height: '50px' }}
                    className="form-control infoInput size16600"
                    placeholder="Title"
                    type="text"
                    value={search}
                    onKeyDown={handleKeyEnter}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <BiSearch onClick={() => handleSearchWebform()} size={20} style={{ float: 'right', marginTop: '-33px', marginRight: '10px', cursor: 'pointer' }} />
            </div>
            <div className="col-12 col-md-1 col-sm-12 mt-md-0 mt-3">
                <div className="d-flex justify-content-end" style={{ position: 'relative' }}>
                    <button
                        className="items-center"
                        id="dropdownMenuLink"
                        onClick={() => {
                            setFilterToggle(!filterToggle)
                            setBeforeActive(false)
                            setAfterActive(false)
                            setFromActive(false)
                            setToActive(false)
                            setSubmissionToggle(false)
                        }}
                        style={{
                            height: '50px',
                            width: '50px',
                            backgroundColor: 'white',
                            border: '1px solid #ced4da',
                            borderRadius: '5px'
                        }}>
                        <img alt="filter" src={FilterIcon} />
                    </button>
                    <div className={`dropdown-menu filter-body ${filterToggle ? 'show' : ''}`} style={{ top: '50px', zIndex: 1 }} aria-labelledby="dropdownMenuLink">
                        <div className="d-flex flex-row align-items-center justify-content-between" style={{ marginBottom: '7px' }}>
                            <a href onClick={() => handleClearFilter()} className="size12600 editColor" style={{ cursor: 'pointer' }}>Clear filters</a>
                            <img alt="close" src={CloseIcon} style={{ cursor: 'pointer' }} onClick={() => {
                                setFilterToggle(!filterToggle)
                                setBeforeActive(false)
                                setAfterActive(false)
                                setFromActive(false)
                                setToActive(false)
                                setSubmissionToggle(false)
                            }} />
                        </div>
                        <div className="progress-body" style={{ marginBottom: '8px' }}>
                            <p className="size12600" style={{ color: '#949494', marginBottom: '6px' }}>Progress Status</p>
                            {
                                dataDontationHistory.map((item, i) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '6px' }} >
                                        <input
                                            onChange={(event) => handleProgressStatus(event, item.value, i)}
                                            checked={item.isCheck}
                                            type="checkbox"
                                            className='comprefBox'
                                            style={{ marginRight: '10px', transform: 'scale(1.1)', cursor: 'pointer' }}
                                        />
                                        <label className="form-check-label size12500" htmlFor="defaultCheck1">
                                            {item.name}
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="submission-body">
                            <p className="size12600" style={{ color: '#949494', marginBottom: '6px' }}>Request Type</p>
                            <div style={{ position: 'relative' }}>
                                <button class="items-between"
                                    style={{
                                        height: '31px',
                                        width: '100%',
                                        backgroundColor: 'white',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        padding: '9px'
                                    }}
                                    onClick={() => {
                                        setTypeActive(!typeActive)
                                    }}
                                >
                                    <p className="size12500 mb-0">{`${requestType ? requestType : "Select One"}`}</p>
                                    <img alt="" src={DropdownIcon} />
                                </button>
                                <div className={`dropdown-menu submission-body ${typeActive ? 'show' : ''}`} style={{ zIndex: 10 }}>
                                    {dataRequestType.map((item, index) => (
                                        <>
                                            <a href class="dropdown-item size12500" onClick={() => {
                                                setRequestType(item.name)
                                                setTypeActive(false)
                                            }} style={{ padding: '9px', cursor: 'pointer' }}>{item.name}</a>
                                            {index + 1 !== dataSearch.length ? <div className="dropdown-divider"></div> : ''}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="submission-body mt-2">
                            <p className="size12600" style={{ color: '#949494', marginBottom: '6px' }}>Submission Date</p>
                            <div className="" style={{ position: 'relative' }}>
                                <button class="items-between"
                                    style={{
                                        height: '31px',
                                        width: '100%',
                                        backgroundColor: 'white',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        padding: '9px'
                                    }}
                                    onClick={() => {
                                        setSubmissionToggle(!submissionToggle)
                                        setBeforeActive(false)
                                        setAfterActive(false)
                                        setFromActive(false)
                                        setToActive(false)
                                    }}
                                >
                                    <p className="size12500 mb-0">{`${submissionValue ? submissionValue : "Select One"}`}</p>
                                    <img alt="" src={DropdownIcon} />
                                </button>
                                <div className={`dropdown-menu submission-body ${submissionToggle ? 'show' : ''}`} style={{ zIndex: 10 }}>
                                    {dataSubmission.map((item, index) => (
                                        <>
                                            <a href class="dropdown-item size12500" onClick={() => {
                                                setSubmissionValue(item.name)
                                                setSubmissionToggle(false)
                                                setBeforeDate("")
                                                setAfterDate("")
                                                setToDate("")
                                                setFromDate("")
                                            }} style={{ padding: '9px', cursor: 'pointer' }}>{item.name}</a>
                                            {index + 1 !== dataSubmission.length ? <div className="dropdown-divider"></div> : ''}
                                        </>
                                    ))}
                                </div>
                                {(() => {
                                    switch (submissionValue) {
                                        case 'Before': return (
                                            <button class="items-between"
                                                style={{
                                                    height: '31px',
                                                    width: '100%',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #ced4da',
                                                    borderRadius: '5px',
                                                    padding: '9px',
                                                    position: 'relative',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <p className="size12500 mb-0">{beforeDate ? formatDateAPI(beforeDate) : ""}</p>
                                                <img onClick={() => setBeforeActive(!beforeActive)} style={{ position: 'absolute', right: '9px' }} alt="" src={CalendarIcon} />
                                            </button>
                                        )

                                        case 'After': return (
                                            <button class="items-between"
                                                style={{
                                                    height: '31px',
                                                    width: '100%',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #ced4da',
                                                    borderRadius: '5px',
                                                    padding: '9px',
                                                    position: 'relative',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <p className="size12500 mb-0">{`${afterDate ? formatDateAPI(afterDate) : ""}`}</p>
                                                <img onClick={() => setAfterActive(!afterActive)} style={{ position: 'absolute', right: '9px' }} alt="" src={CalendarIcon} />
                                            </button>
                                        )
                                        case 'Between': return (
                                            <div className="items-between" style={{ marginTop: '10px' }}>
                                                <button class="items-between"
                                                    style={{
                                                        height: '31px',
                                                        width: '100%',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #ced4da',
                                                        borderRadius: '5px',
                                                        padding: '9px',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <p className="size12500 mb-0">{`${fromDate ? formatDateAPI(fromDate) : ""}`}</p>
                                                    <img onClick={() => {
                                                        setFromActive(!fromActive)
                                                        setToActive(false)
                                                    }} style={{ position: 'absolute', right: '9px' }} alt="" src={CalendarIcon} />
                                                </button>
                                                <span className="mx-2">-</span>
                                                <button class="items-between"
                                                    style={{
                                                        height: '31px',
                                                        width: '100%',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #ced4da',
                                                        borderRadius: '5px',
                                                        padding: '9px',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <p className="size12500 mb-0">{`${toDate ? formatDateAPI(toDate) : ""}`}</p>
                                                    <img onClick={() => {
                                                        setToActive(!toActive)
                                                        setFromActive(false)
                                                    }} style={{ position: 'absolute', right: '9px' }} alt="" src={CalendarIcon} />
                                                </button>
                                            </div>
                                        )
                                        default:
                                            return null
                                    }
                                })()}
                            </div>
                            <button onClick={() => handleFilterWebForm()} type="button" disabled={((fromDate && toDate) || afterDate || beforeDate || requestType  || arrStatus.length > 0) ? false : true} class="btn filter_button size12500">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={refOne}>
                <CalendarCSL float={'right'} top="300px" activeCalendar={afterActive} changeDate={changeDateAfter} date={afterDate} handleActiveCalendar={() => setAfterActive(!afterActive)} />
                <CalendarCSL float={'right'} top="300px" activeCalendar={beforeActive} changeDate={changeDateBefore} date={beforeDate} handleActiveCalendar={() => setBeforeActive(!beforeActive)} />
                <CalendarCSL float={'right'} top="300px" activeCalendar={fromActive} changeDate={changeDateFrom} date={fromDate} handleActiveCalendar={() => setFromActive(!fromActive)} />
                <CalendarCSL float={'right'} top="300px" activeCalendar={toActive} changeDate={changeDateTo} date={toDate} handleActiveCalendar={() => setToActive(!toActive)} />
            </div>
        </div>
    )
}

export default SearchFilterHeaderDraft