import React from 'react'
import { Modal, Form } from 'react-bootstrap'
import RoundButton from '../buttons/RoundButton';
import { colors } from '../../constants/theme';

class MessageModalMain extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            message: "",
            type: "danger",
            danger: "danger",
            handleOnHide: null,
            title: null
        }
    }

    show(message, title) {
        this.setState({
            visible: true,
            message,
            handleOnHide: null,
            title
        })
    }

    showNoTitle(message, title, typeNo) {
        this.setState({
            visible: true,
            message,
            handleOnHide: null,
            title,
            typeNo
        })
    }

    showError(message, onHide) {
        this.setState({
            visible: true,
            message,
            type: "danger",
            handleOnHide: onHide,
            title: ''
        })
    }

    showSuccess(message, onHide, title) {
        this.setState({
            visible: true,
            message,
            type: "success",
            handleOnHide: onHide,
            title
        })
    }

    showWarning(message, onHide, title) {
        this.setState({
            visible: true,
            message,
            type: "warning",
            handleOnHide: onHide,
            title
        })
    }

    render() {
        const { message, visible, type, handleOnHide, title, typeNo } = this.state;
        const { textCentered } = this.props;

        let icon = require('../../assets/images/fail-icon.jpg');
        if (type === 'success') {
            icon = require('../../assets/images/icons/Checkmark.png');
        } else if (type === 'warning') {
            icon = require('../../assets/images/Warning.png');
        }

        return (
            <Modal
                show={visible}
                onHide={() => {
                    this.setState({ visible: false })
                    if (handleOnHide) {
                        handleOnHide()
                    }
                }}
                centered
                // dialogClassName="modal-600px"
            >
                <div
                    style={{
                        backgroundColor: "white",
                        borderTop: `5px solid ${colors.primaryColor}`,
                        borderRadius: "4px",
                        padding: '1.5rem 70px'
                    }}
                >

                    <Form.Text className="text-header- primary_title">
                        {title}
                    </Form.Text>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {
                            type !== 'danger' ? <div className="mb-2" style={{ display: 'flex', justifyContent: 'center' }}><img style={{ objectFit: 'cover', height: '54px' }} src={icon} alt="" /></div> : null
                        }

                        <Form.Text style={{ textTransform: 'capitalize', fontWeight: '700', fontSize: '24px', color: 'black' }} className={`text-header- text-center primary_title text-${type}-`}>
                            {
                               typeNo ? "" : type === 'danger' ? 'Oops!' : type + '!'
                            }

                        </Form.Text>
                        <p style={{ fontWeight: '400', fontSize: '16px', lineHeight: '1.3', textAlign: textCentered ? "center" : "inherit", marginTop: '18px' }}>{(typeof message) === 'string' ? message : message}</p>
                    </div>
                    <div className="text-center">
                        <RoundButton
                            onClick={() => {
                                this.setState({ visible: false })
                                if (handleOnHide) {
                                    handleOnHide()
                                }
                            }}
                            title='Close'
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

export default MessageModalMain