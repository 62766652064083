export const dataSubmission = [
  {
    name: "Before",
  },
  {
    name: "After",
  },
  {
    name: "Between",
  },
];

export const dataSearch = [
  {
    name: "Title",
  },
];

export const progressStatus = [
  {
    name: "Pending",
    value: "pending",
    isCheck: false,
  },
  {
    name: "In Review",
    value: "in-review",
    isCheck: false,
  },
  {
    name: "Approved",
    value: "approved"
  },
  {
    name: "In Progress",
    value: "in-progress",
    isCheck: false,
  },
  {
    name: "Campaign Live",
    value: "campaign-live",
    isCheck: false,
  },
  {
    name: "Completed",
    value: "completed",
    isCheck: false,
  },
  {
    name: "Archive",
    value: "archive",
    isCheck: false,
  },
  {
    name: "Request Denied",
    value: "request-denied",
    isCheck: false,
  },
  {
    name: "Request Cancelled",
    value: "request-cancelled",
    isCheck: false,
  }
];

export const dataRequestType = [
  {
    name: "Campaign Request",
    value: "campaign-requests"
  },
  {
    name: "Technical Ticket",
    value: "technical-ticket"
  },
];

export const dataRequestTypeValue = (type) => {
  switch (type) {
    case "Campaign Request":
      return "campaign-requests";
    case "Technical Ticket":
      return "technical-ticket";
  }
};

export const dataRequestTypeList = (type) => {
  switch (type) {
    case "campaign-requests":
      return "Campaign Request";
      break;
    case "technical-ticket":
      return "Technical Ticket";
      break;
    default:
      return ""
      break;
  }
};

export const checkTitle = (campaign) => {
  let key = campaign && campaign.requestType ? campaign.requestType : ""
  switch (key) {
      case `technical-ticket`:
          return campaign && campaign.summaryIssue ? campaign.summaryIssue : ""
          break;
  
      default:
          return campaign && campaign.title ? campaign.title : ""
          break;
  }
}