export function encryptParams(p) {
    if (p) {
      // console.log("HTTP REQUEST: ", p);
    }
  
    return p;
  }
  

export const showTitle = (value, data) => {

  if(value && data && data.length > 0) {

    let findItem = data.find(it => it.value === value)
    
    return findItem ? findItem.label : ""

  }
  return ''

}