import React, { useEffect, useRef, useState } from "react";
import CalendarIcon from '../assets/images/icons/calendar-icon.svg'
import { CalendarCSL } from '../container/calendar/CalendarCSL'
import moment from 'moment';
import { formatDateString } from "../functions/format/dateFormat";

import DropupIcon from '../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../assets/images/icons/dropdown-icon.svg'
import UploadIcon from '../assets/images/icons/upload-icon.svg'
import CloseIcon from '../assets/images/icons/close-icon.svg'
import { sliceURL } from "../functions/sliceURL";
import BlockLoading from '../container/blockLoading/blockLoading'

const CampaignKPIsV2 = (props) => {
    const { campaign, dateRangeError, validateFields, uploadeFile } = props;

    const { handleUploadFile,
        additionalDocumentationAsset,
        setadditionalDocumentationAsset,
        } = uploadeFile;
    const [fileSupport, setFileSupport] = useState({
        additionalDocumentationAsset: "Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)"
    })
    
    const { campaignObject, setCampaignObject } = campaign;
    const [dateFrom, setDateFrom] = useState(campaignObject.startDate)
    const [dateTo, setDateTo] = useState(campaignObject.endDate)
    const [otherCommentActive, setOtherCommentActive] = useState(false)

    const [toActive, setToActive] = useState(false)
    const [fromActive, setFromActive] = useState(false)

    const changeDateTo = (date) => {
        setDateTo(date)
        setCampaignObject({ ...campaignObject, endDate: moment(date).format('YYYY-MM-DD') });
    }
    const changeDateFrom = (date) => {
        setDateFrom(date)
        setCampaignObject({ ...campaignObject, startDate: moment(date).format('YYYY-MM-DD') })
    }

    const [reports, setReport] = useState([
        { type: 'Donor ID', isCheck: 'no' },
        { type: '# of Targets', isCheck: 'no' },
        { type: 'Region/Division/Center', isCheck: 'no' },
        { type: '# of Donations in X Time Period', isCheck: 'no' },
        { type: 'Other', isCheck: 'no' },
    ])

    
    const [newContentLoading, setNewContentLoading] = useState(false)
    const [existingContentLoading, setExistingContentLoading] = useState(false)
    const [uploadNull, setUploadNull] = useState("")
    const [newContentFile, setNewContentFile] = useState([])
    const [existingContentFile, setExistingContentFile] = useState([])

    const toBase64 = (file, type, id) => new Promise((resolve, reject) => {
        const fileName = file.name;
        document.getElementById(id).innerHTML = fileName;
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            handleUploadFile(fileName, base64String, type, setNewContentLoading, setExistingContentLoading);
        };
        reader.readAsDataURL(file);
    });

    const handleFileChange = async (event, type) => {
        const { value, id } = event.target;
        if (id === 'additionalDocumentationAsset') {
            if(existingContentFile.find(element => element === value)){
                return false
            }else {
                fileSupport["additionalDocumentationAsset"] = value
            }
            existingContentFile.push(value)
            setExistingContentFile([...existingContentFile])
        }
        setFileSupport({ ...fileSupport })
        const file = event.target.files[0];
        await toBase64(file, type, id)
    }


    const handleCheck = (event, it, i) => {
        const { value, checked } = event.target;
        if (checked) {
            it.isCheck = 'yes';
        } else {
            it.isCheck = 'no';
        }
        setReport([...reports]);
        setCampaignObject({ ...campaignObject, reportRequest: { ...campaignObject.reportRequest, desiredMetrics: reports } })
    }

    const handleCampaignStatus = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setCampaignObject({ ...campaignObject, campaignStatus: value })
        } else {
            setCampaignObject({ ...campaignObject, campaignStatus: value })
        }
    }

    const handleAddition = (event) => {
        setCampaignObject({ ...campaignObject, additionalComment: event.target.value })
    }

    const [campaignStatusArr, setCampaignStatusArr] = useState([
        { name: 'One Time', type: 'one-time' },
        { name: 'Daily', type: 'daily' },
        { name: 'Weekly', type: 'weekly' },
    ]);

    const handleCommentOther = (e) => {
        setCampaignObject({
            ...campaignObject,
            reportRequest: {
                ...campaignObject.reportRequest,
                comment: e.target.value
            }
        })
    }

    const { campaignStatus, additionalComment, reportRequest, startDate, endDate } = campaignObject;

    useEffect(()=> {
        if(reportRequest){
            if(reportRequest.desiredMetrics){
                if(reportRequest.desiredMetrics.length > 0){
                    setReport(reportRequest.desiredMetrics)
                }
            }
        }
        if(startDate){
            setDateFrom(new Date(formatDateString(startDate)))
        }
        if(endDate){
            setDateTo(new Date(formatDateString(endDate)))
        }

        document.addEventListener("click", handleClickOutSide, true)
        return () => {
            document.removeEventListener('click', handleClickOutSide);
          };
    }, [reportRequest, startDate, endDate])

    const refOne = useRef(null)

    const handleClickOutSide = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setFromActive(false)
            setToActive(false)
        }
    }
    
    return (
        <div className="">
            {/* <div>
                <label for="campaignKpis" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign KPIs / Metrics Required for Report (please select all that apply)</label>
                <div style={{ marginTop: '24px' }} className="row gx-0 gy-3">
                    {
                        reports.map((it, i) => (
                            <div class={`form-check ${i + 2 !== reports.length ? "col-md-4" : "col-md-8"} col-12`}>
                                <input className='input-check' type="checkbox" value={it.type} checked={it.isCheck === 'yes'} onChange={(e) => handleCheck(e, it, i)} id={it.type + i} />
                                <label htmlFor={it.type + i} className="flexCheckDefault size16700">
                                    {it.type}
                                </label>
                            </div>
                        ))
                    }
                </div>
                {
                    reportRequest.desiredMetrics.map((item) => item.type === 'Other' && item.isCheck === 'yes' ? (
                        <div style={{ marginTop: '24px' }}>
                            <label for="campaignKpis" class="form-label size14700" style={{ marginBottom: '4px' }}>Other</label>
                            <textarea class={`form-control custom-input`} id="floatingTextarea2" style={{ height: '100px' }} value={reportRequest.comment} onChange={handleCommentOther}></textarea>
                        </div>
                    ) : null
                    )
                }
            </div> */}
            {/* <div style={{ marginTop: '40px' }}>
                <label for="statusCompaign" class="form-label size18700" style={{ marginBottom: '4px' }}>How often would you like to be updated on the status of your campaign?*</label>
                <div style={{ marginTop: '24px' }}>
                    <div>
                        <div className='row'>
                            <div className="col-md-3 col-12">
                                <div className="d-flex flex-column justify-content-center" style={{ height: '100%' }}>
                                    <label for="campaignDuration" class="form-label size16700" style={{ marginBottom: '4px' }}>Date Range*</label>
                                    {
                                        dateRangeError && (!campaignObject.startDate || !campaignObject.endDate) ? (
                                            <p className='text-danger size12400 mb-0 mt-2'>{dateRangeError}</p>
                                        ) : ''
                                    }
                                </div>
                            </div>
                            <div className="col-md-9 col-12">
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <div className='calendar-box' ref={refOne}>
                                        <p>{dateFrom ? (moment(dateFrom).format('MM / DD / YYYY')) : 'Select date'}</p>
                                        <img onClick={() => { setFromActive(!fromActive); setToActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                                    </div>
                                    <div style={{ width: '10px', height: '1px', backgroundColor: 'black', marginLeft: '16px', marginRight: '16px' }}></div>
                                    <div className='calendar-box' ref={refOne}>
                                        <p>{dateTo ? (moment(dateTo).format('MM / DD / YYYY')) : 'Select date'}</p>
                                        <img onClick={() => { setToActive(!toActive); setFromActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={refOne}>
                            <CalendarCSL float={'left'} activeCalendar={fromActive} setactiveCalendar={setFromActive} changeDate={changeDateFrom} maxDate={true}  date={dateFrom} handleActiveCalendar={() => { setFromActive(!fromActive); setToActive(false); }} />
                            <CalendarCSL float={'right'} activeCalendar={toActive} setactiveCalendar={setToActive} changeDate={changeDateTo} maxDate={true} date={dateTo} handleActiveCalendar={() => { setToActive(!toActive); setFromActive(false); }} />
                        </div>
                    </div>
                    <div style={{ marginTop: '24px' }} className="row gx-0 gy-3">
                        {
                            campaignStatusArr.map((it, i) => (
                                <div class="form-check col-12 col-md-4">
                                    <input className='input-check' type="checkbox" value={it.type} checked={it.type === campaignStatus} onChange={(e) => handleCampaignStatus(e, it, i)} id={it.name + i} />
                                    <label htmlFor={it.name + i} className="flexCheckDefault size16700">
                                        {it.name}
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                    {
                        validateFields && !campaignStatus ? (
                            <p className='text-danger size12400 mb-0 mt-2'>{validateFields.campaignStatus}</p>
                        ) : ''
                    }
                </div>
            </div> */}
            {campaignObject.requestType === "campaign-requests" ? <div className="mt-4">
                <label for="additionalComments" class="form-label size14700" style={{ marginBottom: '4px' }}>Additional Comments</label>
                <textarea class="form-control custom-input" id="floatingTextarea2" style={{ height: '100px' }} onChange={(e) => handleAddition(e)} value={additionalComment} ></textarea>
            </div> : ""}

            <div className="col-12 mt-4">
            <label for="additionalComments" class="form-label size14700" style={{ marginBottom: '4px' }}>Any additional documentation to share for this request?</label>
                {/* <div className='d-flex flex-row align-items-center mb-2'>
                    <label for="exitingContent" class="form-label size18700" style={{ marginTop: '6px' }}>Existing Content / Design Assets to be Leveraged</label>
                </div> */}
                <div className="d-flex flex-column">
                    <div className="upload-file-body">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                                <img src={UploadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                <p className="size16700 mb-0">Upload file</p>
                            </div>
                            <input id="additionalDocumentationAsset" type="file" value={uploadNull} onChange={(e) => handleFileChange(e, 'additionalDocumentationAsset')} multiple placeholder="CSLPLASMA.pdf" />
                            <div className="d-flex flex-column">
                                {
                                    additionalDocumentationAsset.length > 0 ? additionalDocumentationAsset.map((item, index) => (
                                        <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                            <a target="_blank" href={item.url} className="mb-0 design-upload">{sliceURL(item.url).slice(14)}</a>
                                            <div onClick={() => {
                                                additionalDocumentationAsset.splice(index, 1)
                                                setadditionalDocumentationAsset([...additionalDocumentationAsset])
                                                existingContentFile.splice(index, 1)
                                                setExistingContentFile([...existingContentFile])
                                                setUploadNull("")
                                            }} style={{ marginLeft: '10px' }} className="transparent-button">
                                                <img alt="" src={CloseIcon} style={{ width: '15px' }} />
                                            </div>
                                        </div>
                                    )) : <p className="size14600 mb-0" style={{ color: '#8B8B8B' }}>Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)</p>
                                }
                            </div>
                        </div>
                        {
                            existingContentLoading ? <BlockLoading /> : null
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CampaignKPIsV2;