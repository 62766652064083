import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatDate2, formatDateString, FeedESTDateToString } from '../functions/format/dateFormat';
import '../styles/editFormIntake.css';
import LoadingOverlay from '../container/loading/LoadingOverlay';
import { BoldListField, MainListField } from "../container/listFields/mainListFields";
import DownloadIcon from '../assets/images/download-icon-new.svg'
import { capitalizeFirst } from "../functions/format/stringFormat";
import PageNotFound from "./pageNotFound";


const SubmitViewIntakeForm = (props) => {
    const {dataNew} = props
    const [compaignData, setCompaignData] = useState()
    const [dataEnity, setDataEnity] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('cam')) {
            fetchViewByID(dataNew);
        } else {
            console.log("Fail!")
        }
    }, [])

    const fetchViewByID = (dataNew) => {
        let data = dataNew;
        if (data) {
            data.addKeyMessage.map((item) => {
                item.channelCount = item.channel.filter(co => co.isCheck === 'yes').length
            })
            setCompaignData(data);
        } else {
            console.log("Fail!");
            setDataEnity(true);
        }
    }

    const sliceURL = (sliceURL) => {
        let fileNameUpload = sliceURL;
        let parser = document.createElement('a');
        parser.href = fileNameUpload;
        let fileName = parser.pathname.split('/').slice(1);
        return fileName.pop()
    };

    const getFileNameShort = (sliceURL) => {
        if (sliceURL.length > 30) {
            return sliceURL.substr(0, 30) + '...' + sliceURL.substr(-11)
        }

        return sliceURL
    }

    let desiredMetricsIndex = 0;
    let personalizationInndex = 0;
    let commentIndex = 0;
    let donorType = 0;
    let locationIndex = 0;
    let personalIndex = 0;
    if (dataEnity) {
        return <PageNotFound />
    }
    
    let contentDesignAssetNew = compaignData && compaignData.contentDesignAsset.length > 0 ? compaignData.contentDesignAsset.filter(it => it.url) : []
    let contentDesignAssetData = contentDesignAssetNew.length > 0 ? [
        {
            "keyLanguage": "English",
            "files": contentDesignAssetNew
        }
    ] : compaignData && compaignData.contentDesignAsset.length > 0 ? compaignData.contentDesignAsset : []

    let designAssetLeverageNew = compaignData && compaignData.designAssetLeverage.length > 0 ? compaignData.designAssetLeverage.filter(it => it.url) : []
    let designAssetLeverageData = designAssetLeverageNew.length > 0 ? [
        {
            "keyLanguage": "English",
            "files": contentDesignAssetNew
        }
    ] : compaignData && compaignData.designAssetLeverage.length ? compaignData.designAssetLeverage : []

    return (
        <div className="main-form d-flex flex-column align-items-center">
            {compaignData ? <form className='form-group mb-1'>
                <div className="">
                    <div style={{ marginBottom: '50px' }}>
                        <h3 className="FormIntakeH3" style={{ marginBottom: '8px' }}>{compaignData && compaignData.title}</h3>
                        <p className="size18700 mb-0">Submission Date: {compaignData ? FeedESTDateToString(compaignData.submitDate) : ''}</p>
                        {
                            compaignData && compaignData.reSubmitDate ? <p className="size16700 mb-0 mt-1">Resubmitted on: {compaignData ? FeedESTDateToString(compaignData.reSubmitDate) : ''}</p>
                                : ''
                        }
                        <p className='size14520 mb-0'>Please be advised: Digital campaigns take approximately 2 weeks from approval to deployment.</p>
                    </div>
                    {/* Stakeholder/Requester */}
                    <div>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                {/* Stakeholder/Requester */}
                                <p className="titleStakeholder mb-40px">Stakeholder/Requester</p>
                                <div className="row gy-40px">
                                    <div className="col-md-8 col-sm-12">
                                        <MainListField label="First Name" value={compaignData ? compaignData.firstName : ''} />
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <MainListField label="Last Name" value={compaignData ? compaignData.lastName : ''} />
                                    </div>
                                    <div className="col-md-8 col-sm-12">
                                        <MainListField label="Email" value={compaignData ? compaignData.email : ''} />
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <MainListField label="Department" value={compaignData ? compaignData.department : ''} />
                                    </div>
                                    <div className="col-12">
                                        <MainListField label="Email Addresses for Testing Campaign" value={compaignData ? compaignData.emailTesting : ''} />
                                    </div>
                                </div>
                                <div className="csl-red-line"></div>
                                {/* Campaign Details */}
                                <p className="titleStakeholder mb-40px">Campaign Details</p>
                                <div className="row gy-40px">
                                    <div className="col-12">
                                        <BoldListField label="Campaign Title*" value={compaignData ? compaignData.title : ''} />
                                    </div>
                                    <div className="col-12">
                                        <BoldListField label="Campaign Goal*" value={compaignData ? compaignData.goal : ''} />
                                    </div>
                                    <div className="col-12">
                                        <BoldListField
                                            label="Campaign Duration*"
                                            value={`${formatDateString(compaignData ? compaignData.startDuration.slice(0, 10) : '')} — ${formatDateString(compaignData ? compaignData.endDuration.slice(0, 10) : '')}`}
                                        />
                                    </div>
                                </div>
                                <div className="csl-red-line"></div>
                                {/* Audience */}
                                <p className="titleStakeholder mb-40px">Audience</p>
                                <div className="row gy-40px">
                                    <div className="col-12">
                                        <p htmlFor="basic-url" className="size18700">Initial Target Audience*</p>
                                        <div className="d-flex flex-column">
                                            {
                                                compaignData ? compaignData.targetAudience.map((item) => {
                                                    if (item.inputType === "donor-type") {
                                                        const donorTypes = item.donorType.filter(fd => fd != 'Other');
                                                        const isOther = item.donorType.find(item => item == 'Other');
                                                        return (
                                                            <div style={{ marginTop: '16px' }}>
                                                                <label htmlFor="" className="infoLabel1">Donor Type</label>
                                                                <p className="" style={{ fontSize: '16px' }}>
                                                                    {donorTypes.join(', ')}
                                                                </p>
                                                                {(isOther === 'Other' && item.additionalText) &&
                                                                    <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p>
                                                                }
                                                            </div>
                                                        )
                                                    } else if (item.inputType === "donation-history") {
                                                        return (
                                                            <div style={{ marginTop: '16px' }}>
                                                                <label htmlFor="basic-url" className="infoLabel1">Donation History</label>
                                                                {
                                                                    item.noDonation === "yes" ? <p>No Donation</p> : ''
                                                                }
                                                                {
                                                                    item.donationAmount.enable === "yes" ? <p className="" style={{ fontSize: '16px' }}>Donation Amount:
                                                                        {
                                                                            item.donationAmount.key === "between" ? ` Between ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""} - ${item.donationAmount.value[1] ? item.donationAmount.value[1] : ""}` : ""
                                                                        }
                                                                        {
                                                                            item.donationAmount.key === "less-then" ? ` Less Than ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""}` : ""
                                                                        }
                                                                        {
                                                                            item.donationAmount.key === "greater-then" ? ` Greater Than ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""}` : ""
                                                                        }
                                                                    </p> : ""
                                                                }
                                                                {
                                                                    item.donationDate.enable === "yes" ? <p className="" style={{ fontSize: '16px' }}>Donation Date:
                                                                        {
                                                                            item.donationDate.key === "before" ? ` Before ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""}` : ""
                                                                        }
                                                                        {
                                                                            item.donationDate.key === "after" ? ` After ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""}` : ""
                                                                        }
                                                                        {
                                                                            item.donationDate.key === "between" ? ` Between ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""} - ${item.donationDate.value[1] ? formatDate2(item.donationDate.value[1]) : ""}` : ""
                                                                        }
                                                                    </p> : ""
                                                                }
                                                                {
                                                                    item.other === "yes" && item.additionalText ? <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p> : ""
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    else if (item.inputType === "location") {
                                                        return (
                                                            <div style={{ marginTop: '16px' }}>
                                                                <label htmlFor="basic-url" className="infoLabel1">Location</label>
                                                                
                                                                {
                                                                    item.allCenter === 'yes' ? <p style={{ fontSize: '16px' }}>{`${!item.typeCenter && !item.typePrCenter ? "All US Centers" : `${item.typeCenter === 'all_us_centers' ? 'All US Centers' : ""}${item.typeCenter === 'all_us_centers' && item.typePrCenter === 'all_pr_centers' ? "," : ""} ${item.typePrCenter === 'all_pr_centers' ? 'All PR Centers' : ""} `}`}</p>
                                                                        :
                                                                        <p className="" style={{ fontSize: '16px' }}>{
                                                                            item.centers ? item.centers.map((lo) => {
                                                                                locationIndex++;
                                                                                return `${(lo)}${locationIndex === item.centers.length ? '' : ', '}`
                                                                            }) : ''
                                                                        }</p>
                                                                }
                                                                {
                                                                    item.other === "yes" && item.additionalText ?
                                                                        <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p>
                                                                        : ''
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                }) : null}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex flex-column">
                                            <label htmlFor="basic-url" className="size18700" style={{ marginBottom: '16px' }}>Audience List</label>
                                            {
                                                compaignData && compaignData.audienceException ? <p className="" style={{ fontSize: '16px', marginBottom:"5px" }}>{compaignData ? compaignData.audienceException : ''}</p> : ''
                                            }
                                            <div className="upload-file-body">
                                                <div className="d-flex flex-row align-items-center justify-content-between">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                        {
                                                            compaignData && compaignData.audienceExceptionAsset.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        {
                                                            compaignData && compaignData.audienceExceptionAsset ? compaignData.audienceExceptionAsset.map((item, index) => (
                                                                <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                    <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                </div>
                                                            )) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="mt-4" style={{ fontSize: '16px' }}>{compaignData && compaignData.audienceExceptionAsset ? compaignData.contentDesignAsset.additionalText : ''}</p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <BoldListField label="Total Approximate Volume of Target" value={compaignData ? compaignData.totalApproximate : ''} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="basic-url" className="size18700">Should this campaign run more than once?</label>
                                        <p className="" style={{ fontSize: '16px' }}>
                                            {compaignData ? compaignData.campaignRunmore.map((item) => {
                                                return item.type;
                                            }) : ''}
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <BoldListField label="Retarget Conditions" value={compaignData ? compaignData.retargetConditions : ''} />
                                    </div>
                                </div>
                                <div className="csl-red-line"></div>
                                {/* Content */}
                                <p className="titleStakeholder mb-40px">Content
                                <p className='size14520 mb-0'>*If your content features Spanish, please have your translation available when submitting this request</p>
                                </p>
                                <div className="row gy-40px">
                                    {
                                        compaignData ? compaignData.addKeyMessage.map((item) => {
                                            let inch = 0;
                                            let chArr = item.channel;
                                            item.countChannels = chArr.filter(it => it.isCheck === 'yes').length
                                            return (
                                                <div className="col-12">
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="basic-url" className="size18700">Key Message*</label>
                                                        <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>{`${item.keyLanguage === 'Spanish- Mexico' || item.keyLanguage === 'Spanish- Puetro Rico' ? item.keyLanguage : item.keyLanguage ? item.keyLanguage : 'English'}: ${item.keyMessage}`}</p>
                                                        <label htmlFor="basic-url" className="infoLabel1">Channels</label>
                                                        <p className="" style={{ fontSize: '16px' }}>
                                                            {
                                                                item ? item.channel.map((ch) => {
                                                                    if (ch.isCheck === 'yes') {
                                                                        inch++;
                                                                        return (`${ch.type === 'sms' ? ch.type.toUpperCase() : capitalizeFirst(ch.type)}${inch === item.countChannels ? '' : ','} `);
                                                                    }
                                                                }) : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }
                                    <div className="col-12">
                                        <label htmlFor="basic-url" className="size18700">Personalization / Dynamic Content</label>
                                        <p className="" style={{ fontSize: '16px' }}>
                                            {
                                                compaignData && compaignData.personalization ? compaignData.personalization[0] && compaignData.personalization[0].dynamicContents.map((its, i) => {

                                                    its.count = compaignData.personalization[0] && compaignData.personalization[0].dynamicContents.filter(ot => ot.isCheck === 'yes' && ot.type != 'Other').length
                                                    if (its.isCheck === 'yes') {
                                                        if (its.type != 'Other') {
                                                            personalizationInndex++;
                                                            return `${its.type}` + `${personalizationInndex === its.count ? '' : ', '}`;
                                                        }
                                                        if (its.type === 'Other' && compaignData.personalization[0].additionalText != '') {
                                                            personalIndex++
                                                        }
                                                    }
                                                }) : ''
                                            }
                                        </p>
                                        {
                                            personalIndex > 0 ? <p className="" style={{ fontSize: '16px' }}>
                                                Other: {compaignData && compaignData.personalization ? compaignData.personalization[0].additionalText : ""}
                                            </p> : ''
                                        }

                                    </div>
                                </div>
                                <div className="csl-red-line"></div>
                                {/* Design */}
                                <p className="titleStakeholder mb-40px">Design</p>
                                <div className="row gy-40px">
                                    <div className="col-12">
                                        <label htmlFor="basic-url" className="size18700">Style Guide Approach</label>
                                        <p className="" style={{ fontSize: '16px' }}>{compaignData ? compaignData.styleGuide : ''}</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex flex-column">
                                            <label htmlFor="basic-url" className="size18700" style={{ marginBottom: '16px' }}>New Content / Design Assets
                                            {/* {compaignData && compaignData.contentDesignAsset.length > 0  && <p className="" style={{ fontSize: '16px' }}>{compaignData.contentDesignAssetLanguage === 'Spanish- Mexico' || compaignData.contentDesignAssetLanguage === 'Spanish- Puetro Rico' ? compaignData.contentDesignAssetLanguage : compaignData.contentDesignAssetLanguage ? compaignData.contentDesignAssetLanguage : 'English'}:</p>} */}
                                            </label>
                                            {contentDesignAssetData && contentDesignAssetData.map(it => (
                                                <div className="d-flex align-items-center mt-2">
                                                    {it.keyLanguage && it.keyLanguage && <span style={{ textWrap: "nowrap" }} >{it.keyLanguage === 'Spanish- Mexico' || it.keyLanguage === 'Spanish- Puetro Rico' ? it.keyLanguage : it.keyLanguage ? it.keyLanguage : 'English'}:</span>}
                                                    <div className="upload-file-body ms-2 w-100">
                                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                                {
                                                                    it && it.files && it.files.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                                }
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                {
                                                                    it && it.files && it.files.length > 0 ? it.files.map((item, index) => (
                                                                        <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                            <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                        </div>
                                                                    )) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                           }
                                            <p className="mt-4" style={{ fontSize: '16px' }}>{compaignData && compaignData.contentDesignAsset ? compaignData.contentDesignAsset.additionalText : ''}</p>
                                            {
                                                compaignData && compaignData.designOther.additionalText ? <p className="" style={{ fontSize: '16px' }}>Other: {compaignData && compaignData.designOther ? compaignData.designOther.additionalText : ''}</p> : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex flex-column">
                                            <label htmlFor="basic-url" style={{ marginBottom: '16px' }} className="size18700">Existing Content / Design Assets to be Leveraged</label>
                                            {designAssetLeverageData && designAssetLeverageData.map(it => (
                                                <div className="d-flex align-items-center mt-2">
                                                    {it.keyLanguage && it.keyLanguage && <span style={{ textWrap: "nowrap" }} >{it.keyLanguage === 'Spanish- Mexico' || it.keyLanguage === 'Spanish- Puetro Rico' ? it.keyLanguage : it.keyLanguage ? it.keyLanguage : 'English'}:</span>}
                                                    <div className="upload-file-body ms-2 w-100">
                                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                                {
                                                                    it && it.files && it.files.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                                }
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                {
                                                                    it && it.files && it.files.length > 0 ? it.files.map((item, index) => (
                                                                        <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                            <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                        </div>
                                                                    )) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <label htmlFor="basic-url" className="infoLabel1 mt-4">Please specify the campaign that currently uses these assets</label>
                                            <p className="mt-2" style={{ fontSize: '16px' }}>{compaignData ? compaignData.specifyCampaignAsset : ''}</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="basic-url" className="size18700">Where will the content be applied?</label>
                                        <p className="" style={{ fontSize: '16px' }}>{compaignData ? compaignData.contentApply : ''}</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex flex-column">
                                            <label htmlFor="basic-url" className="size18700">Has the campaign idea and proposed illustration been approved by the marketing team and legal?*</label>
                                            <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>
                                                {compaignData ? compaignData.campaignIdea.map((item) => {
                                                    return (`${capitalizeFirst(item.type)} `);
                                                }) : ''}
                                            </p>
                                            {compaignData ? compaignData.campaignIdea.map((item) => {
                                                if (item.isCheck === 'yes') {
                                                    return <label htmlFor="basic-url" className="infoLabel1">Please list the people who have approved this campaign*</label>
                                                }
                                            }) : ''}
                                            {
                                                compaignData && compaignData.campaignIdea.find(item => item.isCheck === 'yes') ? <p className="" style={{ fontSize: '16px' }}>{compaignData ? compaignData.peopleApprove : ''}</p> : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="csl-red-line"></div>

                                {/* Campaign Mandatories */}
                                <p className="titleStakeholder mb-40px">Campaign Mandatories</p>
                                <div className="row gy-40px">
                                    <div className="col-12">
                                        <label htmlFor="basic-url" className="size18700">Has the campaign offer/budget been approved?*</label>
                                        <p className="" style={{ fontSize: '16px' }}>
                                            {compaignData ? compaignData.campaignOffer.map((item) => {
                                                return (`${capitalizeFirst(item.type)} `);
                                            }) : ''}
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex flex-column">
                                            <label htmlFor="basic-url" className="size18700">Did this campaign request require approval from legal?*</label>
                                            <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>
                                                {compaignData ? compaignData.legalDeclaimer.map((item) => {
                                                    return (`${capitalizeFirst(item.type)} `);
                                                }) : ''}
                                            </p>
                                            {compaignData.legalDeclaimer ? compaignData.legalDeclaimer.map((item) => {
                                                if (item.isCheck == 'yes') {
                                                    return <label htmlFor="basic-url" className="infoLabel1">Has this been approved by legal?*</label>
                                                }
                                            }) : ''}
                                            {
                                                compaignData.legalDeclaimer ? compaignData.legalDeclaimer.map((item) => {
                                                    if (item.isCheck == 'yes' && compaignData.haveApproved) {
                                                        return <label htmlFor="basic-url" style={{ fontSize: '16px', }}>{compaignData.haveApproved.type ? compaignData.haveApproved.type : ''}</label>
                                                    }
                                                }) : ''
                                            }
                                            {
                                                compaignData ? compaignData.legalDeclaimer.map((item) => {
                                                    if (item.isCheck == 'yes' && compaignData.haveApproved) {
                                                        if (compaignData.haveApproved.isCheck == 'yes') {
                                                            return <label htmlFor="basic-url" style={{ fontSize: '16px', }}>{compaignData.haveApproved.insertName ? `Approved by ${compaignData.haveApproved.insertName}` : ''}</label>
                                                        } else if (compaignData.haveApproved.isCheck == 'no') {
                                                            return <label htmlFor="basic-url" style={{ fontSize: '16px', }}>{compaignData.haveApproved.isConfirm ? `I confirm that this message does not require legal approval and assume responsibility of any inaccuracies or errors in message` : ''}</label>
                                                        } else {
                                                            return '';
                                                        }
                                                    }
                                                }) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <BoldListField label="Campaign KPIs / Success Metrics" value={compaignData ? compaignData.campaignKpi : ''} />
                                    </div>
                                </div>
                                <div className="csl-red-line"></div>

                                {/* Campaign KPIs */}
                                <p className="titleStakeholder mb-40px">Campaign KPIs</p>
                                <div className="row gy-40px">
                                    <div className="col-12">
                                        <label htmlFor="basic-url" className="size18700">Campaign KPIs / Metrics Required for Report</label>
                                        <p className="" style={{ fontSize: '16px' }}>
                                            {compaignData ? compaignData.reportRequest.desiredMetrics.map((item, index) => {
                                                item.count = compaignData.reportRequest.desiredMetrics.filter(it => it.isCheck === 'yes' && it.type != 'Other').length
                                                if (item.isCheck === 'yes') {
                                                    if (item.type != 'Other') {
                                                        desiredMetricsIndex++;
                                                        return `${item.type}` + `${desiredMetricsIndex === item.count ? '' : ', '}`;
                                                    }
                                                    if (item.type === 'Other' && compaignData.reportRequest.comment != '') {
                                                        commentIndex++
                                                    }
                                                }
                                            }) : ''}
                                        </p>
                                        {commentIndex > 0 ? <p className="" style={{ fontSize: '16px' }}>Other: {compaignData.reportRequest.comment}</p> : null}
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="basic-url" className="size18700">How often would you like to be updated on the status of your campaign?*</label>
                                        <p className="text-capitalize" style={{ fontSize: '16px' }}>
                                            {compaignData ? compaignData.campaignStatus.replace("-", " ") : null}
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <div className="">
                                            <label htmlFor="basic-url" className="infoLabel1">Date Range*</label>
                                            <p className="" style={{ fontSize: '16px' }}>
                                                {`${formatDateString(compaignData ? compaignData.startDate.slice(0, 10) : '')} — ${formatDateString(compaignData ? compaignData.endDate.slice(0, 10) : '')}`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <BoldListField label="Additional Comments" value={compaignData ? compaignData.additionalComment : ''} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
                : null}
        </div>
    )
}

export default SubmitViewIntakeForm;