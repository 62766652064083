import React, { useState, useEffect, useRef } from 'react';
import './LoginForm.css';
import API from '../utils/request'
import { LOGIN_URL } from '../api/URLs';
import { Buffer } from 'buffer'
import { storageKeys } from "../constants/storage";
import * as Storage from "../utils/storage";
import MessageModalMain from "../container/modal/MessageModalMain";
import { LOGIN_SUCCESS } from '../modules/auth/constants';
import { connect } from 'react-redux';
import LoadingOverlay from "../container/loading/LoadingOverlay";
import { FiEye, FiEyeOff } from 'react-icons/fi';


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


const LoginPage = (props) => {

    const [password, setPassword] = useState("");
    const [passwordInvalid, setPasswordInvalid] = useState("")
    const [usernameInvalid, setUsernameInvalid] = useState("")
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false)
    const messageModalRef = useRef('rememberMe');
    const [showPassword, setShowPassword] = useState(false);
    const userRef = useRef();

    useEffect(() => {
        if (localStorage.getItem(storageKeys.TOKEN)) {
            window.location.href = "/";
        }
    }, [])

    useEffect(() => {
        userRef.current.focus();
    }, [])
    useEffect(() => {
        const result = USER_REGEX.test(username);
        setUsernameInvalid(result);
    }, [username])

    useEffect(() => {
        const result = PWD_REGEX.test(password);
        setPasswordInvalid(result);
    }, [password])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!username && !password) {
            setUsernameInvalid("Please enter your username!");
            setPasswordInvalid("Please enter your password!");
            return false
        }
        if (!username) {
            setUsernameInvalid("Please enter your username!")
            return false
        }
        if (!password) {
            setPasswordInvalid("Please enter your password!")
            return false
        }
        setLoading(true)
        API.post(LOGIN_URL, {
            "username": username,
            "password": Buffer.from(password).toString('base64')
        })
            .then((res) => {
                setLoading(false)
                if (res.status === 1) {
                    const { token, data } = res;
                    props.dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res
                    })
                    Storage.setString(storageKeys.TOKEN, token);
                    Storage.setJson(storageKeys.USER, data)
                    if (localStorage.getItem('cam')) {
                        window.location.href = "/viewFormIntake?cam="+ localStorage.getItem('cam');
                    } else {
                        window.location.href = "/";
                    }
                } else {
                    messageModalRef.current.showWarning(res.status.message);
                }
            })
            .catch((err) => {
                messageModalRef.current.showWarning("Username or password is incorrect.");
                setLoading(false)
            })
    }
    if (localStorage.getItem(storageKeys.TOKEN)) {
        return null;
    }
    return (
        <div className="pt-5-">
            <h1 className="headerLogin text-center m-5-">Stakeholder Intake Webform</h1>
            <div className='col-12 mt-5 carborderd auth_body bg-white'>
                <div className="">
                    {loading ? <LoadingOverlay /> : null}
                    <MessageModalMain textCentered ref={messageModalRef} />
                    <form onSubmit={handleSubmit}>
                        <h3 className="mt-3 mr-3 headerh3">Login</h3>
                        <div className="">
                            <label className="labelTextLogin">Username <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    value={username}
                                    ref={userRef}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className={`form-control custom-input ${(usernameInvalid && !username) ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                        marginBottom: '5px'
                                    }}
                                />
                                <div className="invalid-feedback">{usernameInvalid && !username ? usernameInvalid : ""}</div>
                            </div>
                        </div>
                        <div>
                            <label className="labelTextLogin">Password <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }}>
                            <div className="d-flex align-items-center">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className={`form-control custom-input hideIcon ${passwordInvalid && !password ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                    }}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                    <span
                                        style={{
                                            position: "absolute",
                                            cursor: "pointer",
                                            right: "10px"
                                        }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? (
                                            <FiEye ></FiEye>
                                        ) : (
                                            <FiEyeOff ></FiEyeOff>
                                        )}
                                    </span>
                            </div>
                                <div className="invalid-feedback" style={{ display: passwordInvalid && !password? 'block' : 'none' }}>{passwordInvalid && !password ? passwordInvalid : ""}</div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <button
                                className="button_auth button_body"
                                style={{ marginTop: "32px" }}
                                onSubmit={() => handleSubmit()}
                            >
                                <p
                                    style={{
                                        fontWeight: "800",
                                        fontSize: "16px",
                                        marginBottom: "0",
                                        color: "white",
                                    }}
                                >
                                    Log In
                                </p>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.authReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);