import React, {useState} from 'react'
// import { StakeholderLoading } from '../../container/loading/StakeholderLoading'

const StakeHolderV2 = (props) => {
    
    const {
        user,
        emailTest,
        setEmailTest
    } = props
    const [loading, setLoading] = useState(true)
    return (
        <div>
            <h2 className='size24700' style={{ marginBottom: '40px' }}>Stakeholder/Requester</h2>
            {/* {loading ? <StakeholderLoading style={{position:'absolute'}} /> : null} */}
            <div className='row fixed-gap'>
                <div className='col-md-6 col-12'>
                    <div>
                        <label for="First-Name" class="form-label size14700" style={{ marginBottom: '4px' }}>First Name</label>
                        <input type="text" value={user?.firstName} class="form-control custom-input  size16400" id="First-Name" aria-describedby="emailHelp" disabled />
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <div>
                        <label for="Last-Name" class="form-label size14700" style={{ marginBottom: '4px' }}>Last Name</label>
                        <input type="text" value={user?.lastName} class="form-control custom-input  size16400" id="Last-Name" aria-describedby="emailHelp" disabled />
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <div>
                        <label for="Emaillabel" class="form-label size14700" style={{ marginBottom: '4px' }}>Email</label>
                        <input type="text" value={user?.primaryEmail} class="form-control custom-input  size16400" id="Emaillabel" aria-describedby="emailHelp" disabled />
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <div>
                        <label for="Department" class="form-label size14700" style={{ marginBottom: '4px' }}>Department</label>
                        <input type="text" value={user?.department} class="form-control custom-input  size16400" id="Department" aria-describedby="emailHelp" disabled />
                    </div>
                </div>
                {/* <div className='col'>
                    <div>
                        <label for="floatingTextarea2" class="form-label size14700" style={{ marginBottom: '4px' }}>Email Addresses for Testing Campaign</label>
                        <textarea type="email" class="form-control custom-input" id="floatingTextarea2" style={{ height: '100px' }} value={emailTest} onChange={(e) => setEmailTest(e.target.value)}></textarea>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default StakeHolderV2