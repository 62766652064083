import { useState } from "react"
import DropupIcon from '../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../assets/images/icons/dropdown-icon.svg'
import { showTitle } from "../utils/string"

const SelectComponent = (props) => {
    const { 
        options,
        keyValue, setKeyValue, 
        titleSelect, isSubmit,
        marginLeft, errorText } = props
    const [dateActive, setDateActive] = useState(false)
    // const [keyValue, setKeyValue] = useState("")
    

    // const [options, setOptions] = useState([
    //     {
    //         label: "Campaign Request",
    //         value: "campaign-requests"
    //     },
    //     {
    //         label: "Technical Ticket",
    //         value: "technical-ticket"
    //     },
    // ])

    const handleSelect = (item) => {
        setKeyValue(item.value)
        setDateActive(false)
    }


    return <div className="w-100">
    <div className="w-100" style={{ position: 'relative', marginLeft: marginLeft ? marginLeft : "5px" }}>
        <button
            style={{
                height: '50px',
                width: '100%',
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                border: `1px solid ${errorText ? "#DC3545" : "#ced4da"}`,
                borderRadius: '5px',
                padding: '9px',
                position: 'relative',
                marginRight: '16px'
            }}
            onClick={(e) => {
                e.preventDefault();
                setDateActive(!dateActive)
            }}
            className='d-flex flex-row align-items-center'
        >
            <p className="size16600 mb-0">{keyValue ? showTitle(keyValue, options) : titleSelect}</p>
            <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={dateActive ? DropupIcon : DropdownIcon} width='18px' />
        </button>
        <div className={`cus-width-drop- dropdown-menu pt-0 pb-0 ${dateActive ? 'show' : ''}`} style={{ zIndex: 11 }}>
            {options && options.map((item, index) => (
                <>
                    <a href class="dropdown-item size16400" onClick={() => handleSelect(item)} style={{ padding: '9px', cursor: 'pointer' }}>{item.label}</a>
                    {index + 1 !== options.length ? <div className="dropdown-divider"></div> : ''}
                </>
            ))}
        </div>
    </div>

    
    {errorText ? <div style={{position: "absolute"}} className="invalid-feedback d-block ps-1">{errorText}</div> : ""}
    </div>
}

export default SelectComponent;