import React, { useEffect, useState } from "react";
import KeyMessage from "./appendKeyMessage";
import AppendKeyMessageV2 from "./appendKeyMessageV2";
import SelectComponent from "./SelectComponent";
import { capitalizeFirst } from "../functions/format/stringFormat";

const ContentV2 = (props) => {
    const {
        addKeyMessage,
        setAddKeyMessage,
        personalization,
        setPersonalization,
        submits,
        campaignObject, setCampaignObject,
        validateFields
    } = props
    const {isSubmit} = submits

    const [otherActive, setOtherActive] = useState()

    function handleClick() {
        addKeyMessage.push({
            keyMessage: '', 
            keyLanguage: '',
            channel: [
                { "type": "email", "isCheck": "no" },
                { "type": "sms", "isCheck": "no" },
                { "type": "app", "isCheck": "no" }
            ]
        })
        setAddKeyMessage([...addKeyMessage]);
    }

    const handleDynamicContent = (event, i, index) => {
        const contents = [...personalization];

        if (event.target.checked) {
            contents[index]['dynamicContents'][i].isCheck = 'yes';
            setPersonalization(contents);
        } else {
            contents[index]['dynamicContents'][i].isCheck = 'no';
            setPersonalization(contents);
        }
        if (event.target.id === 'Other') {
            setOtherActive(event.target.checked)
            if (!event.target.checked) {
                contents[index].additionalText = ''
            }
        }
    }

    const handleOtherMessage = (event, index) => {
        const contents = [...personalization];
        contents[index].additionalText = event.target.value;
        setPersonalization(contents);
    }

    const handleRemoveMessage = (index) => {
        addKeyMessage.splice( addKeyMessage.length - 1 , 1);
        setAddKeyMessage([...addKeyMessage]);
    }

    useEffect(()=> {
        if(personalization && personalization.length > 0){
            personalization.forEach(item => {
                let otherFind = item.dynamicContents.find(value => value.type === 'Other' && value.isCheck === 'yes' )
                if(otherFind){
                    setOtherActive(true)
                }
            });
        }
    }, [personalization])
    
    const [requestType, setRequestType] = useState("")

    function handleClick() {
        addKeyMessage.push({
            keyMessage: '', 
            keyLanguage: '',
            channel: [
                { "type": "email", "isCheck": "no" },
                { "type": "sms", "isCheck": "no" },
                { "type": "app", "isCheck": "no" }
            ]
        })
        setAddKeyMessage([...addKeyMessage]);
    }
    let optionsLangFor = [
        {
            label: "United State",
            value: "United State"
        },
        {
            label: "Peurto Rico",
            value: "Peurto Rico"
        },
        {
            label: "Both",
            value: "Both"
        },
    ]
    let optionsLang = [
        {
            label: "US - English",
            value: "US - English"
        },
        {
            label: "US - Spanish",
            value: "US - Spanish"
        },
        {
            label: "Puerto Rico - Spanish",
            value: "Puerto Rico - Spanish"
        },
    ]
    let titleSelect = "Select request for"

    const handleChannel = (event, i, ch) => {
        
        let contents = campaignObject.languageApply ? [...campaignObject.languageApply] : [];

        if (event.target.checked) {
            let contentsFind = contents ? contents.find(it => it.type === ch.label) : null
            if(contentsFind) {
                contents = contents.map(it => {
                    if(it.type === ch.label) {
                        it = {
                            "type": ch.label,
                            "isCheck": "yes"
                        }
                    }
                    return it
                })
            } else {
                contents.push(
                    {
                        "type": ch.label,
                        "isCheck": "yes"
                    }
                )
            }
        } else {
            contents = contents.filter(it => ch.label !== it.type || !it.type )
        }
        setCampaignObject({...campaignObject, languageApply: contents})
    };

    const languageApplyChecked = (type) => {
        let typeCheck = campaignObject && campaignObject.languageApply && campaignObject.languageApply.find(it => it.type === type)
        return typeCheck && typeCheck.isCheck === "yes"
    }
    
    const languageApplyCheckeds = (type) => {
        let typeCheck = campaignObject && campaignObject.languageApply && campaignObject.languageApply.filter(it => it.isCheck === "yes")
        return typeCheck && typeCheck.length > 0 ? false : true
    }

    const languageApplyCheckedShowText = (type) => {
        let types = ['US - Spanish', 'Puerto Rico - Spanish']
        let typeCheck = campaignObject && campaignObject.languageApply && campaignObject.languageApply.filter(it => it.isCheck === "yes" && types.includes(it.type))
        return typeCheck && typeCheck.length > 0 ? true : false
    }

    return (
        <div className="">
            <h2 className='size24700' style={{ marginBottom: '40px' }}>Content
            {/* <p className='size14520 mb-0 mt-1'>*If your content features Spanish, please have your translation available when submitting this request</p> */}
            </h2>
            <div className='d-flex flex-row align-items-center' style={{marginBottom: "21px"}}>
                <label for="kegalDisclaimer" class="form-label size18700 pe-4 text-wrap-mode" style={{marginBottom: '0px'}}>This request is for</label>
                <SelectComponent
                    // addKeyMessage={addKeyMessage}
                    // setAddKeyMessage={setAddKeyMessage}
                    titleSelect={titleSelect}
                    options={optionsLangFor}
                    keyValue={campaignObject.contentRequest ? campaignObject.contentRequest : ""}
                    setKeyValue={(value) => {
                        setCampaignObject({ ...campaignObject, contentRequest: value })
                    }}

                />
            </div>

            <div id="languageApply" style={{ marginTop: '12px' }}>
                <label for="channel" class="form-label size14700" style={{ marginBottom: '15px' }}>Language (please select all that apply)*</label>
                {
                    optionsLang.length > 0 ? <div className="row gx-0 gy-3 mt-16px-">
                        {
                            optionsLang.map((ch, i) => (
                                <div class="form-check col-12 col-md-4">
                                    <input
                                        className='input-check'
                                        type="checkbox"
                                        id={ch.value + i}
                                        value={ch.value}
                                        checked={languageApplyChecked(ch.label)}
                                        onChange={(e) => handleChannel(e, i, ch)}
                                    />
                                    <label className='size14700' htmlFor={ch.label + i} >
                                        {ch.label}
                                    </label>
                                </div>
                            ))
                        }
                    </div> : null
                }
                {validateFields.languageApply && isSubmit && languageApplyCheckeds() && <p className='text-danger size12400 mb-0 mt-2'>{validateFields.languageApply}</p>}
                {languageApplyCheckedShowText() ? <p className='text-danger- size14520 mb-0 mb-0 mt-2'>
                    If your content features Spanish, please have your translation available when submitting this request. If you need support in transcreation, add ~3 business days to timeline.
                </p> : ""}
            </div>

            {/* <div>
                <div>
                    <AppendKeyMessageV2 addKeyMessage={addKeyMessage} setAddKeyMessage={setAddKeyMessage} submits={submits} handleRemoveMessage={handleRemoveMessage}/>
                    <label
                        for="appendKeyMessage" class="form-label size14700" style={{ cursor: 'pointer', color: 'red', marginTop: '40px' }}
                        onClick={handleClick}>
                        +Add Key Message</label> &nbsp; &nbsp; 
                </div>
            </div> */}
            {/* {
                personalization.map((item, index) => (
                    <div key={index}>
                        <div className="mt-4">
                            <label for="personalization" class="form-label size18700" style={{ marginBottom: '4px' }}>Personalization / Dynamic Content (please select all that apply)</label>
                            {
                                item.dynamicContents.length > 0 ? (
                                    <div className="row gx-0 gy-3 mt-16px">
                                        {
                                            item.dynamicContents.map((value, ind) => (
                                                <div class="form-check col-12 col-md-4">
                                                    <input
                                                        className='input-check'
                                                        type="checkbox"
                                                        value={value.type}
                                                        id={value.type}
                                                        checked={value.isCheck === 'yes' ? true : false}
                                                        onChange={(e) => handleDynamicContent(e, ind, index)} />
                                                    <label for={value.type} className="size16700 flexCheckDefault">
                                                        {value.type}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                        {
                            otherActive && (
                                <div style={{ marginTop: '26px' }}>
                                    <label for="floatingTextarea2" class="form-label size14700" style={{ marginBottom: '4px' }}>Other</label>
                                    <textarea onChange={(event) => handleOtherMessage(event, index)} value={item.additionalText} class="form-control custom-input" id="floatingTextarea2" style={{ height: '100px' }}></textarea>
                                </div>
                            )
                        }
                    </div>
                ))
            } */}
        </div>
    )
}

export default ContentV2;