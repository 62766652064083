import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatDate2, formatDateString, FeedESTDateToString } from '../functions/format/dateFormat';
import '../styles/editFormIntake.css';
import { BoldListField, MainListField } from "../container/listFields/mainListFields";
import DownloadIcon from '../assets/images/download-icon-new.svg'
import { capitalizeFirst } from "../functions/format/stringFormat";
import PageNotFound from "./pageNotFound";
import { checkTitle, dataRequestTypeList } from "../container/FixedData/fixedData";


const SubmitViewIntakeFormV2 = (props) => {
    const { dataNew } = props
    const [compaignData, setCompaignData] = useState()
    const [dataEnity, setDataEnity] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('cam')) {
            fetchViewByID(dataNew);
        } else {
            console.log("Fail!")
        }
    }, [])

    const fetchViewByID = (dataNew) => {
        let data = dataNew;
        if (data) {
            data.addKeyMessage.map((item) => {
                item.channelCount = item.channel.filter(co => co.isCheck === 'yes').length
            })
            setCompaignData(data);
        } else {
            console.log("Fail!");
            setDataEnity(true);
        }
    }

    const sliceURL = (sliceURL) => {
        let fileNameUpload = sliceURL;
        let parser = document.createElement('a');
        parser.href = fileNameUpload;
        let fileName = parser.pathname.split('/').slice(1);
        return fileName.pop()
    };

    const getFileNameShort = (sliceURL) => {
        if (sliceURL.length > 30) {
            return sliceURL.substr(0, 30) + '...' + sliceURL.substr(-11)
        }

        return sliceURL
    }

    let desiredMetricsIndex = 0;
    let personalizationInndex = 0;
    let commentIndex = 0;
    let donorType = 0;
    let locationIndex = 0;
    let personalIndex = 0;
    if (dataEnity) {
        return <PageNotFound />
    }

    let contentDesignAssetNew = compaignData && compaignData.contentDesignAsset.length > 0 ? compaignData.contentDesignAsset.filter(it => it.url) : []
    let contentDesignAssetData = contentDesignAssetNew.length > 0 ? [
        {
            "keyLanguage": "English",
            "files": contentDesignAssetNew
        }
    ] : compaignData && compaignData.contentDesignAsset.length > 0 ? compaignData.contentDesignAsset : []

    let designAssetLeverageNew = compaignData && compaignData.designAssetLeverage.length > 0 ? compaignData.designAssetLeverage.filter(it => it.url) : []
    let designAssetLeverageData = designAssetLeverageNew.length > 0 ? [
        {
            "keyLanguage": "English",
            "files": contentDesignAssetNew
        }
    ] : compaignData && compaignData.designAssetLeverage.length ? compaignData.designAssetLeverage : []

    const languageApplyCheckedShowText = (type) => {
        let types = ['US - Spanish', 'Puerto Rico - Spanish']
        let typeCheck = compaignData && compaignData.languageApply && compaignData.languageApply.filter(it => it.isCheck === "yes" && types.includes(it.type))
        return typeCheck && typeCheck.length > 0 ? true : false
    }

    return (
        <div className="main-form d-flex flex-column align-items-center">
            {compaignData ? <form className='form-group mb-1'>
                <div className="">
                    <div style={{ marginBottom: '50px' }}>
                        <h3 className="FormIntakeH3" style={{ marginBottom: '8px' }}>{checkTitle(compaignData)}</h3>
                        {
                            compaignData && compaignData.isSave === "draft" ?
                                <p className="size18700 mb-0">Create Date: {compaignData ? FeedESTDateToString(compaignData.createDate) : ''}</p>
                                :
                                <p className="size18700 mb-0">Submission Date: {compaignData ? FeedESTDateToString(compaignData.submitDate) : ''}</p>
                        }
                        {
                            compaignData && compaignData.reSubmitDate ? <p className="size16700 mb-0 mt-1">Resubmitted on: {compaignData ? FeedESTDateToString(compaignData.reSubmitDate) : ''}</p>
                                : ''
                        }
                        <p className='size14520 mb-0'>Please be advised: Digital campaigns take approximately 2 weeks from approval to deployment.</p>
                    </div>
                    {/* Stakeholder/Requester */}
                    <div>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                {/* Stakeholder/Requester */}
                                <p className="titleStakeholder mb-40px">Stakeholder/Requester</p>
                                <div className="row gy-40px">
                                    <div className="col-md-8 col-sm-12">
                                        <MainListField label="First Name" value={compaignData ? compaignData.firstName : ''} />
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <MainListField label="Last Name" value={compaignData ? compaignData.lastName : ''} />
                                    </div>
                                    <div className="col-md-8 col-sm-12">
                                        <MainListField label="Email" value={compaignData ? compaignData.email : ''} />
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <MainListField label="Department" value={compaignData ? compaignData.department : ''} />
                                    </div>
                                </div>
                                <div className="csl-red-line"></div>
                                <p className="titleStakeholder mb-40px">Request Types
                                    <p className='size16520 mb-0'>{compaignData ? dataRequestTypeList(compaignData.requestType) : ''}</p>
                                    {
                                        compaignData && compaignData.requestType === "technical-ticket" ? "" :
                                            <p className='size16520 mb-0'>{compaignData ? compaignData.adhocEvergreen && compaignData.adhocEvergreen.title : ''}</p>
                                    }
                                </p>
                                {
                                    compaignData && compaignData.requestType === "technical-ticket" ?
                                        <div className="col-12 mb-40px">
                                            <BoldListField label="Summary of issue*" value={compaignData ? compaignData.summaryIssue : ''} />
                                        </div> : ""
                                }
                                {
                                    <div className="col-12 mb-40px">
                                        {compaignData && compaignData.dateDiscovered || compaignData && compaignData.dateResolution ? (
                                            <BoldListField
                                                label="Date of issue discovered*"
                                                value={`${compaignData && compaignData.dateDiscovered ? formatDateString(compaignData && compaignData.dateDiscovered.slice(0, 10)) : ''}`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                }
                                {
                                    <div className="col-12 mb-40px">
                                        {compaignData && compaignData.dateDiscovered || compaignData && compaignData.dateResolution ? (
                                            <BoldListField
                                                label="Due date for desired resolution*"
                                                value={`${compaignData && compaignData.dateResolution ? formatDateString(compaignData && compaignData.dateResolution.slice(0, 10)) : ''}`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                }
                                {
                                    compaignData && compaignData.requestType === "technical-ticket" ?
                                        <div className="col-12">
                                            {compaignData && compaignData.additionalDocumentation.length > 0 ? (
                                                <div className="d-flex flex-column">
                                                    <BoldListField
                                                        label="Any additional documentation to share for this request?"
                                                    />
                                                    <div className="upload-file-body mt-2">
                                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                                {
                                                                    compaignData && compaignData.additionalDocumentation.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                                }
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                {
                                                                    compaignData && compaignData.additionalDocumentation ? compaignData.additionalDocumentation.map((item, index) => (
                                                                        <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                            <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                        </div>
                                                                    )) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div> : ""
                                }
                                {
                                    compaignData && compaignData.requestType === "campaign-requests" ?
                                        <>
                                            {/* Campaign Details */}
                                            <p className="titleStakeholder mb-40px">Campaign Details</p>
                                            <div className="row gy-30px">
                                                <div className="col-12">
                                                    <BoldListField label="Campaign Title*" value={compaignData ? compaignData.title : ''} />
                                                </div>
                                                <div className="col-12 mb-40px">
                                                    <BoldListField label="" value=
                                                        {
                                                            compaignData ? compaignData.addKeyMessage && compaignData.addKeyMessage.map((item) => {
                                                                let inch = 0;
                                                                let chArr = item.channel;
                                                                item.countChannels = chArr.filter(it => it.isCheck === 'yes').length
                                                                return (
                                                                    <div className="col-12">
                                                                        <div className="d-flex flex-column">
                                                                            <label htmlFor="basic-url" className="size18700">Campaign Goal / Key Message*</label>
                                                                            <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>{`${item.keyMessage}`}</p>
                                                                            <label htmlFor="basic-url" className="infoLabel1">Channels</label>
                                                                            <p className="" style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    item ? item.channel.map((ch) => {
                                                                                        if (ch.isCheck === 'yes') {
                                                                                            inch++;
                                                                                            return (`${ch.type === 'sms' ? ch.type.toUpperCase() : capitalizeFirst(ch.type)}${inch === item.countChannels ? '' : ','} `);
                                                                                        }
                                                                                    }) : ''}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : null
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <BoldListField
                                                        label="Campaign Duration*"
                                                        value={`${formatDateString(compaignData ? compaignData.startDuration && compaignData.startDuration.slice(0, 10) : '')} — ${formatDateString(compaignData ? compaignData.endDuration && compaignData.endDuration.slice(0, 10) : '')}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="csl-red-line"></div>
                                            {/* Audience Details */}
                                            <p className="titleStakeholder mb-40px">Audience</p>
                                            <div className="row gy-40px">
                                                <div className="col-12">
                                                    <p htmlFor="basic-url" className="size18700">Initial Target Audience*</p>
                                                    <div className="d-flex flex-column">
                                                        {
                                                            compaignData && compaignData.targetAudience ? compaignData.targetAudience.map((item) => {
                                                                if (item.inputType === "donor-type") {
                                                                    const donorTypes = item.donorType.filter(fd => fd != 'Other');
                                                                    const isOther = item.donorType.find(item => item == 'Other');
                                                                    return (
                                                                        <div style={{ marginTop: '16px' }}>
                                                                            <label htmlFor="" className="infoLabel1">Donor Type</label>
                                                                            <p className="" style={{ fontSize: '16px' }}>
                                                                                {donorTypes.join(', ')}
                                                                            </p>
                                                                            {(isOther === 'Other' && item.additionalText) &&
                                                                                <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p>
                                                                            }
                                                                        </div>
                                                                    )
                                                                } else if (item.inputType === "donation-history") {
                                                                    return (
                                                                        <div style={{ marginTop: '16px' }}>
                                                                            <label htmlFor="basic-url" className="infoLabel1">Donation History</label>
                                                                            {
                                                                                item.noDonation === "yes" ? <p>No Donation</p> : ''
                                                                            }
                                                                            {
                                                                                item.donationAmount.enable === "yes" ? <p className="" style={{ fontSize: '16px' }}>Donation Amount:
                                                                                    {
                                                                                        item.donationAmount.key === "between" ? ` Between ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""} - ${item.donationAmount.value[1] ? item.donationAmount.value[1] : ""}` : ""
                                                                                    }
                                                                                    {
                                                                                        item.donationAmount.key === "less-then" ? ` Less Than ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""}` : ""
                                                                                    }
                                                                                    {
                                                                                        item.donationAmount.key === "greater-then" ? ` Greater Than ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""}` : ""
                                                                                    }
                                                                                </p> : ""
                                                                            }
                                                                            {
                                                                                item.donationDate.enable === "yes" ? <p className="" style={{ fontSize: '16px' }}>Donation Date:
                                                                                    {
                                                                                        item.donationDate.key === "before" ? ` Before ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""}` : ""
                                                                                    }
                                                                                    {
                                                                                        item.donationDate.key === "after" ? ` After ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""}` : ""
                                                                                    }
                                                                                    {
                                                                                        item.donationDate.key === "between" ? ` Between ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""} - ${item.donationDate.value[1] ? formatDate2(item.donationDate.value[1]) : ""}` : ""
                                                                                    }
                                                                                </p> : ""
                                                                            }
                                                                            {
                                                                                item.other === "yes" && item.additionalText ? <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p> : ""
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                                else if (item.inputType === "location") {
                                                                    return (
                                                                        <div style={{ marginTop: '16px' }}>
                                                                            <label htmlFor="basic-url" className="infoLabel1">Location</label>
                                                                            {
                                                                                item.allCenter === 'yes' ? <p style={{ fontSize: '16px' }}>{`${!item.typeCenter && !item.typePrCenter ? "All US Centers" : `${item.typeCenter === 'all_us_centers' ? 'All US Centers' : ""}${item.typeCenter === 'all_us_centers' && item.typePrCenter === 'all_pr_centers' ? "," : ""} ${item.typePrCenter === 'all_pr_centers' ? 'All PR Centers' : ""} `}`}</p>
                                                                                    :
                                                                                    <p className="" style={{ fontSize: '16px' }}>{
                                                                                        item.centers ? item.centers.map((lo) => {
                                                                                            locationIndex++;
                                                                                            return `${(lo)}${locationIndex === item.centers.length ? '' : ', '}`
                                                                                        }) : ''
                                                                                    }</p>
                                                                            }
                                                                            {
                                                                                item.other === "yes" && item.additionalText ?
                                                                                    <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            }) : null}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <BoldListField label="Audience List" value={compaignData ? compaignData.audienceException : ''} />
                                                </div>
                                                <div className="col-12 mt-2">
                                                    <div className="d-flex flex-column">
                                                        <div className="upload-file-body">
                                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                                    {
                                                                        compaignData && compaignData.audienceExceptionAsset.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                                    }
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    {
                                                                        compaignData && compaignData.audienceExceptionAsset ? compaignData.audienceExceptionAsset.map((item, index) => (
                                                                            <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                                <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                            </div>
                                                                        )) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <BoldListField label="Does this campaign have a control group?*" value={compaignData && compaignData.controlGroup === "yes" ? "Yes" : 'No'} />
                                                </div>
                                                {
                                                    compaignData && compaignData.controlGroup === "yes" ?
                                                        <div className="col-12">
                                                            <label htmlFor="infoLabel1" className={`infoLabel1`}>{`List count of control group*`}</label>
                                                            <p className="" style={{ fontSize: '16px' }}>{compaignData ? compaignData.totalApproximate : ''}</p>
                                                        </div> : ""
                                                }
                                            </div>
                                            <div className="csl-red-line"></div>
                                            {/* Content Details */}
                                            <div className="">
                                                <p className="titleStakeholder mb-40px">Content</p>
                                                <p htmlFor="basic-url" className="size18700">This request is for </p>
                                                <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>{compaignData && compaignData.contentRequest}</p>
                                                <div className="row gy-40px">
                                                    {compaignData && compaignData?.languageApply?.some(item => item.isCheck === 'yes') && (
                                                        <div className="col-12">
                                                            <div className="d-flex flex-column">
                                                                <label htmlFor="basic-url" className="size18700">Language</label>
                                                                <p className="" style={{ fontSize: '16px' }}>
                                                                    {compaignData.languageApply
                                                                        .filter(item => item.isCheck === 'yes')
                                                                        .map(item => item.type)
                                                                        .join(', ')}
                                                                </p>
                                                                {languageApplyCheckedShowText() && (
                                                                    <p className="" style={{ fontSize: '16px' }}>
                                                                        If your content features Spanish, please have your translation available when submitting this request. If you need support in transcreation, add ~3 business days to timeline.
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="csl-red-line"></div>
                                            {/* Design */}
                                            <div className="">
                                                <p className="titleStakeholder mb-40px">Design</p>
                                                <div className="row gy-40px">
                                                    <div className="col-12">
                                                        <label htmlFor="basic-url" className="size18700">Where will the content be applied?</label>
                                                        <p className="" style={{ fontSize: '16px' }}>{compaignData ? compaignData.contentApply : ''}</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-flex flex-column">
                                                            {
                                                                compaignData && compaignData?.campaignIdea
                                                                    ?.map((item, index) => {
                                                                        return (
                                                                            <div className="col-12" key={index}>
                                                                                <div className="d-flex flex-column">
                                                                                    <label htmlFor="basic-url" className="size18700">Has the campaign idea and proposed illustration been approved by the marketing team and legal?*</label>
                                                                                    <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>{item.type}</p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        compaignData?.campaignIdea
                                                            ?.filter(item => item.isCheck === 'yes') // Filter items where isCheck is 'yes'
                                                            ?.length > 0 && ( // Check if there are any filtered items
                                                            <div className="col-12">
                                                                <label htmlFor="basic-url" className="size18700">Please list the people who have approved this campaign*</label>
                                                                <p className="" style={{ fontSize: '16px' }}>{compaignData.peopleApprove || ''}</p>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                            <div className="csl-red-line"></div>
                                            {/* Campaign Mandatories */}
                                            <p className="titleStakeholder mb-40px">Campaign Mandatories</p>
                                            <div className="row gy-40px mb-40px">
                                                <div className="col-12">
                                                    <label htmlFor="basic-url" className="size18700">Has the campaign offer/budget been approved?*</label>
                                                    <p className="" style={{ fontSize: '16px' }}>
                                                        {compaignData ? compaignData.campaignOffer && compaignData.campaignOffer.map((item) => {
                                                            return (`${capitalizeFirst(item.type)} `);
                                                        }) : ''}
                                                    </p>
                                                </div>
                                                <div className="col-12">
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="basic-url" className="size18700">Does your campaign require a disclaimer?</label>
                                                        <p className="" style={{ fontSize: '16px' }}>
                                                            {compaignData && compaignData.legalDeclaimer ? compaignData.legalDeclaimer && compaignData.legalDeclaimer.map((item) => {
                                                                return (`${capitalizeFirst(item.type)} `);
                                                            }) : ''}
                                                        </p>
                                                        {
                                                            compaignData && compaignData.legalDeclaimer && compaignData.legalDeclaimer.map((item) => (
                                                                <>
                                                                    {
                                                                        item && item.isCheck === 'yes' ?
                                                                            <div style={{ marginTop: '16px' }}>
                                                                                <label htmlFor="basic-url" className="infoLabel1">Has your disclaimer been approved by legal?*</label>
                                                                                <div>
                                                                                    <p style={{ fontSize: '16px' }}>{item && item.type}</p>
                                                                                </div>
                                                                            </div> : ""
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                        {
                                                            compaignData ? compaignData.legalDeclaimer && compaignData.legalDeclaimer.map((item) => {
                                                                if (item.isCheck == 'yes' && compaignData.haveApproved) {
                                                                    if (compaignData.haveApproved.isCheck == 'yes') {
                                                                        return <label htmlFor="basic-url" style={{ fontSize: '16px', }}>{compaignData.haveApproved.insertName ? `Approved by ${compaignData.haveApproved.insertName}` : ''}</label>
                                                                    } else if (compaignData.haveApproved.isCheck == 'no') {
                                                                        return <label htmlFor="basic-url" style={{ fontSize: '16px', }}>{compaignData.haveApproved.isConfirm ? `I confirm that this message does not require legal approval and assume responsibility of any inaccuracies or errors in message` : ''}</label>
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                }
                                                            }) : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-40px">
                                                <BoldListField label="Additional Comments" value={compaignData ? compaignData.additionalComment : ''} />
                                            </div>
                                            <div className="col-12">
                                                {compaignData && compaignData.additionalDocumentation.length > 0 ? (
                                                    <div className="d-flex flex-column">
                                                        <BoldListField
                                                            label="Any additional documentation to share for this request?"
                                                        />
                                                        <div className="upload-file-body mt-2">
                                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                                    {
                                                                        compaignData && compaignData.additionalDocumentation.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                                    }
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    {
                                                                        compaignData && compaignData.additionalDocumentation ? compaignData.additionalDocumentation.map((item, index) => (
                                                                            <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                                <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                            </div>
                                                                        )) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </> : ""
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </form>
                : null}
        </div>
    )
}

export default SubmitViewIntakeFormV2;