import React, { useEffect } from 'react'
import { useState } from 'react'
import DropupIcon from '../../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../../assets/images/icons/dropdown-icon.svg'
import DonorType from '../DonorType'
import SelectDonationHistory from '../SelectDonationHistory'
import LocationCenter from '../LocationCenter'
import UploadIcon from '../../assets/images/icons/upload-icon.svg'
import CloseIcon from '../../assets/images/icons/close-icon.svg'
import { sliceURL } from "../../functions/sliceURL";
import BlockLoading from '../../container/blockLoading/blockLoading'

const AudienceV2 = (props) => {

    const {
        submits,
        targetAudience,
        setDonorType,
        donorType,
        setDonorTypeOtherComment,
        donorTypeOtherComment,
        validateFields,
        locationAll, 
        setLocationAll,
        locationAllPR, 
        setLocationAllPR,
        //case 2
        keyAmount,
        setKeyAmount,
        setKeyDate,
        keyDate,
        setOtherComment,
        otherComment,
        setDonationHistory,
        donationHistory,
        setDonationAmount,
        donationAmount,
        dateAmount,

        //case 3
        setCheckAllLocation,
        checkAllLocation,
        setSelectedState,
        selectedState,
        setSelectedCities,
        selectedCities,
        setSelectedCenter,
        selectedCenter,
        setLocationCheckOther,
        locationCheckOther,
        setLocationOtherComment,
        locationOtherComment,

        audienceExceptions,
        setAudienceExceptions,
        setTotatApproximate,
        totalApproximate,
        retargetConditions,
        setRetargetConditions,
        setCampaignRunmore,
        campaignRunmore,
        selectedTypes,
        validAuds,
        uploadeFile,
        controlGroup,
        setcontrolGroup,
    } = props;
    const { selectedDonorTypes, setSelectedDonorTypes,
        initialLabel, setInitialLabel,
        secondLabel, setSecondLabel,
        thirdLabel, setThirdLabel } = selectedTypes
    const { validAud, setValidAud, validateAudience } = validAuds
    const { isSubmit, setisSubmit } = submits

    const [initialActive, setInitialActive] = useState()
    const [secondActive, setSecondActive] = useState()
    const [thirdActive, setThirdActive] = useState()

    const [initialValue, setInitialValue] = useState()
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const [selectedOption3, setSelectedOption3] = useState('');
    const [campaginRunActive, setCampaignRunActive] = useState()

    const [filteredOptions2, setFilteredOptions2] = useState([]);
    const [filteredOptions3, setFilteredOptions3] = useState([]);

    const menuOptions = [
        { value: "donor-type", label: "Donor Type" },
        { value: "donation-history", label: "Donation History" },
        { value: "location", label: "Location" }
    ];
    
    const { handleUploadFile,
        audienceExceptionAsset,
        setaudienceExceptionAsset,
        } = uploadeFile;
    const [fileSupport, setFileSupport] = useState({
        audienceExceptionAsset: "Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)"
    })

    const [newContentLoading, setNewContentLoading] = useState(false)
    const [existingContentLoading, setExistingContentLoading] = useState(false)
    const [uploadNull, setUploadNull] = useState("")
    const [newContentFile, setNewContentFile] = useState([])
    const [existingContentFile, setExistingContentFile] = useState([])

    const toBase64 = (file, type, id) => new Promise((resolve, reject) => {
        const fileName = file.name;
        document.getElementById(id).innerHTML = fileName;
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            handleUploadFile(fileName, base64String, type, setNewContentLoading, setExistingContentLoading);
        };
        reader.readAsDataURL(file);
    });

    const handleFileChange = async (event, type) => {
        const { value, id } = event.target;
        if (id === 'audienceExceptionAsset') {
            if(existingContentFile.find(element => element === value)){
                return false
            }else {
                fileSupport["audienceExceptionAsset"] = value
            }
            existingContentFile.push(value)
            setExistingContentFile([...existingContentFile])
        }
        setFileSupport({ ...fileSupport })
        const file = event.target.files[0];
        await toBase64(file, type, id)
    }

    useEffect(() => {
        if(targetAudience){
            let initialValue = ""
            let selectedOption2 = ""
            targetAudience.forEach((it, index) => {
                switch (it.inputType) {
                    case 'donor-type':
                        if(index === 0 ) {
                            setInitialLabel('Donor Type')
                            setInitialValue('donor-type')
                            initialValue = 'donor-type'
                            setInitialActive(false)
                            setFilteredOptions2(menuOptions.filter(option => option.value !== initialValue));
                            setFilteredOptions3(menuOptions.filter(option => option.value !== initialValue && option.value !== selectedOption2));
                            initialBody()
                        } else if(index == 1) {
                            setSecondLabel('Donor Type')
                            setSelectedOption2('donor-type');
                            selectedOption2 = 'donor-type'
                            setSecondActive(false)
                            setFilteredOptions3(menuOptions.filter(option => option.value !== selectedOption2 && option.value !== initialValue));
                            initialBody();
                        } else {
                            setThirdLabel('Donor Type')
                            setSelectedOption3('donor-type');
                            setThirdActive(false)
                            initialBody();
                        }
                        break;
                    case 'donation-history':
                        if(index === 0 ) {
                            setInitialLabel('Donation History')

                            setInitialValue('donation-history')
                            initialValue = 'donation-history'
                            setInitialActive(false)
                            setFilteredOptions2(menuOptions.filter(option => option.value !== initialValue));
                            setFilteredOptions3(menuOptions.filter(option => option.value !== initialValue && option.value !== selectedOption2));
                            initialBody()
                        } else if(index == 1) {
                            setSecondLabel('Donation History')
                            setSelectedOption2('donation-history');
                            selectedOption2 = 'donation-history'
                            setSecondActive(false)
                            setFilteredOptions3(menuOptions.filter(option => option.value !== selectedOption2 && option.value !== initialValue));
                            initialBody();
                        } else {
                            setThirdLabel('Donation History')
                            setSelectedOption3('donation-history');
                            setThirdActive(false)
                            initialBody();
                        }
                        break;
                    case 'location':
                        if(index === 0 ) {
                            setInitialLabel('Location')
                            setInitialValue('location')
                            initialValue = 'location'
                            setInitialActive(false)
                            setFilteredOptions2(menuOptions.filter(option => option.value !== initialValue));
                            setFilteredOptions3(menuOptions.filter(option => option.value !== initialValue && option.value !== selectedOption2));
                            initialBody()
                        } else if(index == 1) {
                            setSecondLabel('Location')
                            setSelectedOption2('location');
                            selectedOption2 = 'location'
                            setSecondActive(false)
                            setFilteredOptions3(menuOptions.filter(option => option.value !== selectedOption2 && option.value !== initialValue));
                            initialBody();
                        } else {
                            setThirdLabel('Location')
                            setSelectedOption3('location');
                            // setThirdLabel(item.label)
                            setThirdActive(false)
                            initialBody();
                        }
                        break;
                }
            });
        }
    }, [ targetAudience])

    const handleOption1 = (item) => {
        setInitialValue(item.value)
        setInitialLabel(item.label)

        setSecondLabel('')
        setThirdLabel('')
        setSelectedOption2('')
        setSelectedOption3('')

        setInitialActive(false)
        setFilteredOptions2(menuOptions.filter(option => option.value !== item.value));
        setFilteredOptions3(menuOptions.filter(option => option.value !== initialValue && option.value !== selectedOption2));
        initialBody()
    }

    const handleOption2Change = (item) => {
        setSelectedOption2(item.value);
        setSecondLabel(item.label);
        setThirdLabel('')
        setSelectedOption3('')
        setSecondActive(false)
        setFilteredOptions3(menuOptions.filter(option => option.value !== item.value && option.value !== initialValue));
        initialBody();
    }

    const handleOption3Change = (item) => {
        setSelectedOption3(item.value);
        setThirdLabel(item.label)
        setThirdActive(false)
        initialBody();
    }

    function initialBody(selectedOption) {
        switch (selectedOption) {
            case 'donor-type':
                return <DonorType
                    setDonorType={setDonorType}
                    donorType={donorType}
                    setDonorTypeOtherComment={setDonorTypeOtherComment}
                    donorTypeOtherComment={donorTypeOtherComment}
                    validateAudience={validateAudience}
                />;
                break;
            case 'donation-history':
                return <SelectDonationHistory
                    keyAmount={keyAmount}
                    setKeyAmount={setKeyAmount}
                    setKeyDate={setKeyDate}
                    keyDate={keyDate}
                    setOtherComment={setOtherComment}
                    otherComment={otherComment}
                    setDonationHistory={setDonationHistory}
                    donationHistory={donationHistory}
                    donationAmount={donationAmount}
                    setDonationAmount={setDonationAmount}
                    dateAmount={dateAmount}
                    validateAudience={validateAudience}
                />;
                break;
            case 'location':
                return <LocationCenter
                    setCheckAllLocation={setCheckAllLocation}
                    checkAllLocation={checkAllLocation}
                    setSelectedState={setSelectedState}
                    selectedState={selectedState}
                    setSelectedCities={setSelectedCities}
                    selectedCities={selectedCities}
                    setSelectedCenter={setSelectedCenter}
                    selectedCenter={selectedCenter}
                    setLocationCheckOther={setLocationCheckOther}
                    locationCheckOther={locationCheckOther}
                    setLocationOtherComment={setLocationOtherComment}
                    locationOtherComment={locationOtherComment}
                    locationAll={locationAll} 
                    setLocationAll={setLocationAll}
                    locationAllPR={locationAllPR} 
                    setLocationAllPR={setLocationAllPR}
                />;
                break;
            default:
                return null;
        }
    }

    const handleCampaignRun = (e) => {
        const { value, id } = e.target;
        setCampaignRunActive(id)
        setCampaignRunmore([{
            type: value,
            isCheck: id
        }])
    }

    useEffect(()=> {
        if(campaignRunmore.length > 0){
            setCampaignRunActive(campaignRunmore[0].isCheck)
        }
    }, [campaignRunmore])
    
    return (
        <div>
            <h2 className='size24700' style={{ marginBottom: '40px' }}>Audience</h2>
            <div>
                <div id='targetAudience' style={{ position: 'relative' }}>
                    <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Initial Target Audience*</label>
                    <div className='row'>
                        <div className=' col-12'>
                            <button
                                style={{
                                    height: '50px',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    border: `1px solid ${validAud.initialLabel && isSubmit ? "#DC3545" : "#ced4da"}`,
                                    borderRadius: '5px',
                                    padding: '9px',
                                    position: 'relative',
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setInitialActive(!initialActive)
                                }}
                                className='d-flex flex-row align-items-center'
                            >
                                <p className="size16600 mb-0">{initialLabel ? initialLabel : 'Select One'}</p>
                                <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={initialActive ? DropupIcon : DropdownIcon} width='18px' />
                            </button>
                        </div>
                    </div>
                    <div className={`dropdown-menu audience-dropdowns ${initialActive ? 'show' : ''}`} style={{ zIndex: 10, width: '91% !important' }}
                        onMouseLeave={(e) => {
                            setInitialActive(false);
                        }}>
                        {menuOptions.map((item, index) => (
                            <>
                                <a href class="dropdown-item size16400" onClick={() => handleOption1(item)} style={{ padding: '9px', cursor: 'pointer' }}>{item.label}</a>
                                {index + 1 !== menuOptions.length ? <div className="dropdown-divider"></div> : ''}
                            </>
                        ))}
                    </div>
                    {
                        validAud.initialLabel && isSubmit ? (
                            <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                        ) : ''
                    }
                </div>
                {initialValue &&
                    <div style={{ marginTop: '24px' }}>
                        {initialBody(initialValue)}
                    </div>
                }
            </div>
            {
                initialValue && (
                    <div style={{ marginTop: '24px' }}>
                        <div style={{ position: 'relative' }}>
                            <label for="exampleInputEmail1" class="form-label size14700" style={{ marginBottom: '4px' }}>Add Another Condition</label>
                            <div className='row'>
                                <div className=' col-12'>
                                    <button
                                        style={{
                                            height: '50px',
                                            width: '100%',
                                            backgroundColor: 'white',
                                            // border: '1px solid #ced4da',
                                            border: `1px solid ${validAud.secondLabel && isSubmit ? "#DC3545" : "#ced4da"}`,
                                            borderRadius: '5px',
                                            padding: '9px',
                                            position: 'relative'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSecondActive(!secondActive)
                                        }}
                                        className='d-flex flex-row align-items-center'
                                    >
                                        <p className="size16600 mb-0">{secondLabel ? secondLabel : 'Select One'}</p>
                                        { secondLabel ? <label onClick={() => {
                                                if(thirdLabel){
                                                    setSelectedOption2(selectedOption3);
                                                    setSecondLabel(thirdLabel);
                                                    setSelectedOption3('');
                                                    setThirdLabel('');
                                                    let findValue = menuOptions.find( itLa => itLa.label === secondLabel )
                                                    setFilteredOptions3(menuOptions.filter(option => option.value === findValue.value && option.value !== initialValue));
                                                    initialBody();
                                                } else {
                                                    setSelectedOption2('');
                                                    setSecondLabel('');
                                                    let findValue = menuOptions.find( itLa => itLa.label === secondLabel )
                                                    setFilteredOptions3(menuOptions.filter(option => option.value === findValue.value && option.value !== initialValue));
                                                    initialBody();
                                                }
                                            }} className='col-1-' style={{position: 'absolute', right: '9px', padding: '10px', zIndex: 1, alignItems: 'center', justifyContent: 'flex-end', color: 'red', cursor: 'pointer'}}>X</label>
                                            : <img style={{ position: 'absolute', right: '9px', zIndex: 1, top: '18px' }} alt="" src={secondActive ? DropupIcon : DropdownIcon} width='18px' />}
                                    </button>
                                </div>
                            </div>
                            <div className={`dropdown-menu audience-dropdown ${secondActive ? 'show' : ''}`} style={{ zIndex: 10 }}
                                onMouseLeave={(e) => {
                                    setSecondActive(false);
                                }}>
                                {filteredOptions2.map((item, index) => (
                                    <>
                                        <a href class="dropdown-item size16400" onClick={() => handleOption2Change(item)} style={{ padding: '9px', cursor: 'pointer' }}>{item.label}</a>
                                        {index + 1 !== filteredOptions2.length ? <div className="dropdown-divider"></div> : ''}
                                    </>
                                ))}
                            </div>
                            {
                                validAud.secondLabel && isSubmit ? (
                                    <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                                ) : ''
                            }
                        </div>
                        {selectedOption2 &&
                            <div style={{ marginTop: '24px' }}>
                                {initialBody(selectedOption2)}
                            </div>
                        }
                    </div>
                )
            }
            {
                selectedOption2 && (
                    <div style={{ marginTop: '24px' }}>
                        <div style={{ position: 'relative' }}>
                            <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Add Another Condition</label>
                            <div className='row'>
                                <div className=' col-12'>
                                <button
                                    style={{
                                        height: '50px',
                                        width: '100%',
                                        backgroundColor: 'white',
                                        // border: '1px solid #ced4da',
                                        border: `1px solid ${validAud.thirdLabel && isSubmit ? "#DC3545" : "#ced4da"}`,
                                        borderRadius: '5px',
                                        padding: '9px',
                                        position: 'relative'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setThirdActive(!thirdActive)
                                    }}
                                    className='d-flex flex-row align-items-center'
                                >
                                    <p className="size16600 mb-0">{thirdLabel ? thirdLabel : 'Select One'}</p>
                                    {thirdLabel ? <label onClick={() => {
                                            if(thirdLabel){
                                                setSelectedOption3('');
                                                setThirdLabel('');
                                            }
                                        }} className='col-1-' style={{position: 'absolute', right: '9px', padding: '10px', zIndex: 1, color: 'red', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer'}}>X</label>
                                        :  <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={thirdActive ? DropupIcon : DropdownIcon} width='18px' /> }
                                </button>
                                </div>
                            </div>
                            <div className={`dropdown-menu audience-dropdown ${thirdActive ? 'show' : ''}`} style={{ zIndex: 10}}
                                onMouseLeave={(e) => {
                                    setThirdActive(false);
                                }}>
                                {filteredOptions3.map((item, index) => (
                                    <>
                                        <a href class="dropdown-item size16400" onClick={() => handleOption3Change(item)} style={{ padding: '9px', cursor: 'pointer' }}>{item.label}</a>
                                        {index + 1 !== filteredOptions3.length ? <div className="dropdown-divider"></div> : ''}
                                    </>
                                ))}
                            </div>
                            {
                                validAud.thirdLabel && isSubmit ? (
                                    <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                                ) : ''
                            }
                            {selectedOption3 &&
                                <div style={{ marginTop: '24px' }}>
                                    {initialBody(selectedOption3)}
                                </div>
                            }
                        </div>
                    </div>
                )
            }
            <div style={{ marginTop: '40px' }}>
                <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Audience List</label>
                <textarea class="form-control custom-input" id="floatingTextarea2" value={audienceExceptions} onChange={(e) => setAudienceExceptions(e.target.value)} style={{ height: '100px' }}></textarea>
            </div>

            <div className="col-12 mt-3">
                <div className="d-flex flex-column">
                    <div className="upload-file-body">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                                <img src={UploadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                <p className="size16700 mb-0">Upload file</p>
                            </div>
                            <input id="audienceExceptionAsset" type="file" value={uploadNull} onChange={(e) => handleFileChange(e, 'audienceExceptionAsset')} multiple placeholder="CSLPLASMA.pdf" />
                            <div className="d-flex flex-column">
                                {
                                    audienceExceptionAsset.length > 0 ? audienceExceptionAsset.map((item, index) => (
                                        <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                            <a target="_blank" href={item.url} className="mb-0 design-upload">{sliceURL(item.url).slice(14)}</a>
                                            <div onClick={() => {
                                                audienceExceptionAsset.splice(index, 1)
                                                setaudienceExceptionAsset([...audienceExceptionAsset])
                                                existingContentFile.splice(index, 1)
                                                setExistingContentFile([...existingContentFile])
                                                setUploadNull("")
                                            }} style={{ marginLeft: '10px' }} className="transparent-button">
                                                <img alt="" src={CloseIcon} style={{ width: '15px' }} />
                                            </div>
                                        </div>
                                    )) : <p className="size14600 mb-0" style={{ color: '#8B8B8B' }}>Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)</p>
                                }
                            </div>
                        </div>
                        {
                            existingContentLoading ? <BlockLoading /> : null
                        }
                    </div>
                </div>
            </div>

            <div id='controlGroup' className='row' style={{ marginTop: '40px' }}>
                <div className='col-12'>
                    <div className='d-flex flex-row align-items-center' style={{ height: '100%' }}>
                        <p className='size18700 mb-0'>Does this campaign have a control group?* </p>
                    </div>
                </div>
                <div className='col-12 mt-3 d-flex flex-row' style={{ position: 'relative' }}>
                    {[
                        {
                            name: "Yes",
                            value: "yes",
                        },
                        {
                            name: "No",
                            value: "no",
                        },
                    ].map((item, index) => (
                        <div class="form-check" style={{ marginRight: '80px' }}>
                            <input
                                className='input-check'
                                onChange={() => {
                                    setcontrolGroup(item.value)
                                }}
                                type="checkbox"
                                value={item.value}
                                id={item.value}
                                checked={controlGroup === item.value ? true : false}
                            />
                            <label className="flexCheckDefault size16700">
                                {item.name}
                            </label>
                        </div>
                    ))}
                </div>
                {
                    !controlGroup && isSubmit ? (
                        <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                    ) : ''
                }
            </div>

            {controlGroup === "yes" ? <div id='totalApproximate' className='row' style={{ marginTop: '15px' }}>
                <div className='col-md-7 col-12'>
                    <div className='d-flex flex-row align-items-center' style={{ height: '100%' }}>
                        <p className='size14700 mb-0'>List count of control group*</p>
                    </div>
                </div>
                <div className='col-md col-12'>
                    <input type="number" class="form-control custom-input" value={totalApproximate} onChange={(e) => setTotatApproximate(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp" />
                    {
                        !totalApproximate && isSubmit ? (
                            <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                        ) : ''
                    }
                </div>
            </div> : ""}

            {/* <div style={{ marginTop: '40px' }}>
                <label for="kegalDisclaimer" class="form-label size18700" style={{ marginBottom: '16px' }}>Should this campaign run more than once?</label>
                <div className="d-flex flex-row">
                    <div class="form-check" style={{ marginRight: '80px' }}>
                        <input
                            className='input-check'
                            onChange={handleCampaignRun}
                            type="checkbox"
                            value="Yes"
                            id="yes"
                            checked={campaginRunActive === 'yes' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            className='input-check'
                            onChange={handleCampaignRun}
                            type="checkbox"
                            value="No"
                            id="no"
                            checked={campaginRunActive === 'no' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            No
                        </label>
                    </div>
                </div>
            </div> */}

            {/* <div style={{ marginTop: '40px' }}>
                <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Retarget Conditions (please specify criteria/date/time)</label>
                <textarea class="form-control custom-input" id="floatingTextarea2" value={retargetConditions} onChange={(e) => setRetargetConditions(e.target.value)} style={{ height: '100px' }}></textarea>
            </div> */}
        </div>
    )
}

export default AudienceV2