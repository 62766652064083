import React, { useEffect, useState } from "react";
import DropupIcon from '../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../assets/images/icons/dropdown-icon.svg'

const CampaignMandatoriesV2 = (props) => {

    const {
        campaignKPI,
        setCampaginKPI,
        setCampaignOffer,
        setLegalDislaimer,
        validateFields,
        campaignOffer,
        legalDislaimer,
        specifyLegalDeclaimer,
        setSpecifyLegalDeclaimer,
        haveApproved, setHaveApproved,
        isSubmit,
    } = props
    const [hasOfferActive, setHasOfferActive] = useState()
    const [legalActive, setLegalActive] = useState('')
    const [searchActive, setSearchActive] = useState(false)
    const [haveApprovedText, sethaveApprovedText] = useState("");
    const [haveApprovedShow, sethaveApprovedShow] = useState(false);


    const handleHasCampaign = (e) => {
        const { value, id } = e.target
        setHasOfferActive(id)
        setCampaignOffer([{
            "type": value,
            "isCheck": id
        }])
    }

    const handleLegalDisclaimer = (item) => {
        const { name, value } = item
        setLegalActive(value)
        setLegalDislaimer([{
            "type": name,
            "isCheck": value
        }])
    }

    const handleHaveApproved = (item) => {
        const { name, value } = item
        sethaveApprovedText(value)
        setHaveApproved({
            "title": "Please insert approved legal disclaimer below*",
            "type": name,//Yes
            "isCheck": value, //yes
            "insertName": "",
            "isConfirm": false, // true== checked, false=no check
        });
    }

    const handleIsConfirm = (e) => {
        const isChecked = e.target.checked;
        setHaveApproved({
            ...haveApproved,
            insertName: "",
            isConfirm: isChecked
        })
    }

    useEffect(()=> {
        if(legalDislaimer){
            if(legalDislaimer.length > 0){
                setLegalActive(legalDislaimer[0].isCheck)
                if(legalDislaimer[0].isCheck === 'yes' && haveApproved && haveApproved.isCheck) {
                    sethaveApprovedText(haveApproved.isCheck)
                }
            }
        }
        if(campaignOffer){
            if(campaignOffer.length > 0){
                setHasOfferActive(campaignOffer[0].isCheck)
            }
        }
    }, [legalDislaimer, campaignOffer])

    const dataSearch = [
        {
          name: "Yes",
          value: "yes",
        },
        {
          name: "No",
          value: "no",
        },
      ];

    return (
        <div className="">
            <h2 className='size24700' style={{ marginBottom: '40px' }}>Campaign Mandatories</h2>
            <div id="hasCampaign">
                <label for="hasCampaign" class="form-label size18700" style={{ marginBottom: '16px' }}>Has the campaign offer/budget been approved?*</label>
                <div className="d-flex flex-row">
                    <div class="form-check" style={{ marginRight: '80px' }}>
                        <input
                            className='input-check'
                            onChange={handleHasCampaign}
                            type="checkbox"
                            value="Yes"
                            id="yes"
                            checked={hasOfferActive === 'yes' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            className='input-check'
                            onChange={handleHasCampaign}
                            type="checkbox"
                            value="No"
                            id="no"
                            checked={hasOfferActive === 'no' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            No
                        </label>
                    </div>
                </div>
                {
                    validateFields.campaignOffer && !hasOfferActive ? (
                        <p className='text-danger size12400 mb-0 mt-2'>{validateFields.campaignOffer}</p>
                    ) : ''
                }
            </div>
            <div style={{ marginTop: '40px' }}>
                <label for="kegalDisclaimer" class="form-label size18700" style={{ marginBottom: '16px' }}>Does your campaign require a disclaimer?*</label>
                <div className="col-12 col-md-12 col-sm-12 mt-md-0 mt-3">
                    {/* <div style={{ position: 'relative' }}>
                        <span class="items-between"
                            style={{
                                height: '50px',
                                width: '100%',
                                backgroundColor: 'white',
                                border: !legalActive && isSubmit ? '1px solid rgb(220, 53, 69)' : '1px solid #ced4da',
                                borderRadius: '5px',
                                padding: '9px',
                                cursor: "pointer",
                                textTransform: "capitalize"
                            }}
                            onClick={() => {
                                setSearchActive(!searchActive)
                            }}
                        >
                            <p className="size16600 mb-0">{legalActive ? legalActive : 'Select One'}</p>
                            <img alt="" src={searchActive ? DropupIcon : DropdownIcon} width='18px' />
                        </span>
                        <div className={`dropdown-menu submission-body ${searchActive ? 'show' : ''}`} style={{ zIndex: 10 }}>
                            {dataSearch.map((item, index) => (
                                <>
                                    <a href class="dropdown-item size16600" onClick={() => {
                                        handleLegalDisclaimer(item)
                                        setSearchActive(false)
                                    }} style={{ padding: '9px', cursor: 'pointer' }}>{item.name}</a>
                                    {index + 1 !== dataSearch.length ? <div className="dropdown-divider"></div> : ''}
                                </>
                            ))}
                        </div>
                    </div> */}
                    <div id="legalDislaimer" className="d-flex flex-row">
                        {dataSearch.map((item, index) => (
                            <div class="form-check" style={{ marginRight: '80px' }}>
                                <input
                                    className='input-check'
                                    onChange={() => {
                                        handleLegalDisclaimer(item)
                                        setSearchActive(false)
                                    }}
                                    type="checkbox"
                                    value={item.value}
                                    id={item.value}
                                    checked={legalActive === item.value ? true : false}
                                />
                                <label className="flexCheckDefault size16700">
                                    {item.name}
                                </label>
                            </div>
                        ))}
                        {/* <div class="form-check" style={{ marginRight: '80px' }}>
                            <input
                                className='input-check'
                                onChange={handleHasCampaign}
                                type="checkbox"
                                value="Yes"
                                id="yes"
                                checked={hasOfferActive === 'yes' ? true : false}
                            />
                            <label className="flexCheckDefault size16700">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                className='input-check'
                                onChange={handleHasCampaign}
                                type="checkbox"
                                value="No"
                                id="no"
                                checked={hasOfferActive === 'no' ? true : false}
                            />
                            <label className="flexCheckDefault size16700">
                                No
                            </label>
                        </div> */}
                    </div>
                </div>  
                {
                    !legalActive && isSubmit ? (
                        <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                    ) : ''
                }
            </div>

            {
                legalActive === 'yes' ? (
                    <div style={{ marginTop: '24px' }}>
                        <label for="campaignKpis" class="form-label size14700" style={{ marginBottom: '4px' }}>
                            Has your disclaimer been approved by legal?*

                        </label>
                        <div className="row mt-3">
                            <div className="col-12 d-flex flex-row" style={{ position: 'relative' }}>
                                {dataSearch.map((item, index) => (
                                    <div class="form-check" style={{ marginRight: '80px' }}>
                                        <input
                                            className='input-check'
                                            onChange={() => {
                                                handleHaveApproved(item)
                                                sethaveApprovedShow(false)
                                            }}
                                            type="checkbox"
                                            value={item.value}
                                            id={item.value}
                                            checked={haveApproved.isCheck === item.value ? true : false}
                                        />
                                        <label className="flexCheckDefault size16700">
                                            {item.name}
                                        </label>
                                    </div>
                                ))}
                                {/* <span class="items-between"
                                    style={{
                                        height: '50px',
                                        width: '100%',
                                        backgroundColor: 'white',
                                        border: !haveApproved.isCheck && isSubmit ? '1px solid rgb(220, 53, 69)' : '1px solid #ced4da',
                                        borderRadius: '5px',
                                        padding: '9px',
                                        cursor: "pointer",
                                        textTransform: "capitalize"
                                    }}
                                    onClick={() => {
                                        sethaveApprovedShow(!haveApprovedShow)
                                    }}
                                >
                                    <p className="size16600 mb-0">{haveApproved && haveApproved.isCheck ? haveApproved.isCheck : 'Select One'}</p>
                                    <img alt="" src={haveApprovedShow ? DropupIcon : DropdownIcon} width='18px' />
                                </span>
                                <div className={`dropdown-menu submission-body ${haveApprovedShow ? 'show' : ''}`} style={{ zIndex: 10 }}>
                                    {dataSearch.map((item, index) => (
                                        <>
                                            <a href class="dropdown-item size16600" onClick={() => {
                                                handleHaveApproved(item)
                                                sethaveApprovedShow(false)
                                            }} style={{ padding: '9px', cursor: 'pointer' }}>{item.name}</a>
                                            {index + 1 !== dataSearch.length ? <div className="dropdown-divider"></div> : ''}
                                        </>
                                    ))}
                                </div> */}
                            </div>
                            {
                                !haveApproved.isCheck && isSubmit ? (
                                    <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                                ) : ''
                            }
                        </div>
                    {/* New Code */}

            {
                haveApproved && haveApproved.isCheck === 'yes' ? (
                    <div id="haveApproved" style={{ marginTop: '10px' }}>
                        <label for="campaignKpis" class="form-label size13520700" style={{ marginBottom: '4px' }}>
                            {/* Insert name of person from legal who approved the message* */}
                            Please insert approved legal disclaimer below*
                            
                        </label>
                        {/* <input 
                            class={`form-control custom-input ${!haveApproved.insertName && isSubmit ? 'is-invalid' : ''}`} 
                            id="floatingTextarea2" 
                            value={haveApproved && haveApproved.insertName}
                            onChange={(e) => {
                                setHaveApproved({
                                    ...haveApproved,
                                    insertName: e.target.value,
                                    isConfirm: false
                                })
                            }}></input> */}
                            <textarea 
                            class={`form-control custom-input ${!haveApproved.insertName && isSubmit ? 'is-invalid' : ''}`} 
                            // id="floatingTextarea2" 
                            // value={listPeople} 
                            // onChange={(e) => setListPeople(e.target.value)} style={{ height: '100px' }} 

                            id="floatingTextarea2" 
                            value={haveApproved && haveApproved.insertName}
                            onChange={(e) => {
                                setHaveApproved({
                                    ...haveApproved,
                                    insertName: e.target.value,
                                    isConfirm: false
                                })
                            }}
                            ></textarea>
                            <div className="invalid-feedback">{validateFields.listPeople && validateFields.listPeople}</div>

                        {/* <div className="invalid-feedback">{!haveApproved.insertName && isSubmit && "Fields is Required*"}</div> */}
                    </div>
                ) : null
            }
            {/* {
                haveApproved && haveApproved.isCheck === 'no' ? (<>
                    <div style={{ marginTop: '10px', marginLeft:"10px" }}>
                        <div class="form-check d-flex">
                            <input
                                className='input-check'
                                onChange={handleIsConfirm}
                                type="checkbox"
                                value="No"
                                id="no"
                                checked={haveApproved && haveApproved.isConfirm ? true : false}
                            />
                            <label className="size13520700">
                                I confirm that this message does not require legal approval and assume responsibility of any inaccuracies or errors in message
                            </label>
                        </div>
                    </div>
                    {
                        !haveApproved.isConfirm && isSubmit ? (
                            <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                        ) : ''
                    }
                    </>
                ) : null
            } */}
                    </div>
                ) : null
            } 
            {/* <div style={{ marginTop: '40px' }}>
                <label for="campaignKpis" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign KPIs / Success Metrics</label>
                <textarea class="form-control custom-input" id="floatingTextarea2" style={{ height: '100px' }} value={campaignKPI} onChange={(e) => setCampaginKPI(e.target.value)}></textarea>
            </div> */}
        </div>
    )
}

export default CampaignMandatoriesV2;