export function validationSubmitForm(campaignTitle, campaignGoal, hasCampaign, listPeople, campaignOffer, legalDislaimer, campaignStatus, initialLabel, specifyLegalDeclaimer, requestType) {
    let error = {}

    if (!requestType) {
        error.requestType = "Fields is Required*"
    }
    
    if (!campaignTitle) {
        error.campaignTitle = "Campaign Title is Required*"
    }

    if (!campaignGoal) {
        error.campaignGoal = "Campaign Goal is Required*"
    }

    if (!hasCampaign) {
        error.hasCampaign = "Fields is Required*"
    }

    if (!listPeople) {
        error.listPeople = "Fields is Required*"
    }

    if (!campaignOffer) {
        error.campaignOffer = "Fields is Required*"
    }

    if (!legalDislaimer) {
        error.legalDislaimer = "Fields is Required*"
    }

    if (!campaignStatus) {
        error.campaignStatus = "Fields is Required*"
    }

    if (!initialLabel) {
        error.initialLabel = "Fields is Required*"
    }

    if (!specifyLegalDeclaimer) {
        error.specifyLegalDeclaimer = "Fields is Required*"
    }

    return error
}

export function validationChangePassword(currentPassword, newPassword, confirmPassword) {
    let error = {}

    if (!currentPassword) {
        error.currentPassword = "Current Password is Required*"
    }

    if (!newPassword) {
        error.newPassword = "New Password is Required*"
    }

    if (!confirmPassword) {
        error.confirmPassword = "Confirm New Password Required*"
    }

    return error
}

export function validationResetPassword(newPassword, confirmPassword) {
    let error = {}

    if (!newPassword) {
        error.newPassword = "New Password is Required*"
    }

    if (!confirmPassword) {
        error.confirmPassword = "Confirm New Password Required*"
    }

    return error
}