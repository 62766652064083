export const MainListField = (props) => {
    const { label, value } = props
    return (
        <>
            <label htmlFor="basic-url" className="infoLabel1">{label}</label>
            <p className="" style={{ fontSize: '16px' }}>{value}</p>
        </>
    )
}

export const BoldListField = (props) => {
    const { label, value, className } = props
    return (
        <>
            <label htmlFor="basic-url" className={`size18700 ${className ? className : ""}`}>{label}</label>
            <p className="" style={{ fontSize: '16px' }}>{value}</p>
        </>
    )
}