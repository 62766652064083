import React from 'react'
import { useState } from 'react';
import { CHANGE_PASSWORD } from '../api/URLs';
import PrimaryButton from '../container/buttons/PrimaryButton'
import { validationChangePassword } from '../functions/validation';
import '../styles/changePassword.css'
import { validatePassword } from '../utils/validation';
import { Buffer } from 'buffer'
import API from '../utils/request'
import { colors } from '../constants/Colors';
import LoadingOverlay from '../container/loading/LoadingOverlay';
import MessageModalMain from '../container/modal/MessageModalMain';
import { useRef } from 'react';
import { FiEyeOff, FiEye } from "react-icons/fi";

const ChangePassword = () => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const messageModalRef = useRef('');

    const [currentPassword, setCurrentPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [showNewpassword, setShowNewpassword] = useState(false);
    const [showRepassword, setShowRepassword] = useState(false);

    const logoutHandler = () => {
        localStorage.clear();
        window.location.href = "/login";
    };

    const handleChangePassword = (e) => {
        e.preventDefault()
        setError(validationChangePassword(currentPassword, newPassword, confirmPassword))
        if (!currentPassword || !newPassword || !confirmPassword) {
            return false;
        }
        if (!validatePassword(newPassword)) {
            error['validate'] = 'Password is Invalid'
            setError({ ...error })
            return false;
        }
        if (newPassword !== confirmPassword) {
            error['notMatch'] = 'Password does not match.'
            setError({ ...error })
            return false;
        }
        setLoading(true)
        var params = {
            "oldPassword": Buffer.from(currentPassword).toString('base64'),
            "newPassword": Buffer.from(newPassword).toString('base64'),
        }
        API.post(CHANGE_PASSWORD, params).then((res) => {
            setLoading(false)
            if (res.status === 1) {
                setCurrentPassword("")
                setNewPassword("")
                setConfirmPassword("")
                messageModalRef.current.showSuccess(res.message, () => logoutHandler())
            } else {
                messageModalRef.current.showWarning(res.message)
            }
        }).catch((err) => {
            setLoading(false)
            messageModalRef.current.showWarning(err.message)
        })
    }

    return (
        <div className='row px-lg-0 px-5 gx-0' style={{ marginTop: '40px' }}>
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
            <p className='size20700'>Change Password</p>
            <div className='col-md-8 col-12 margin-password'>
                <div className='row'>
                    <div className='col-md-3 col-12'>
                        <div className='d-flex flex-row align-items-center h-100'>
                            <label className='size16700'>Current Password<span className='text-danger'>*</span></label>
                        </div>
                    </div>
                    <div className='col-md-9 col-12' style={{ position: 'relative' }}>
                        <div className="d-flex align-items-center">
                            <input
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                                className={`form-control custom-input hideIcon ${error.currentPassword && !currentPassword ? 'is-invalid' : ''}`}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    right: "23px"
                                }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? (
                                    <FiEye />
                                ) : (
                                    <FiEyeOff />
                                )}
                            </span>
                        </div>
                        <div className="invalid-feedback" style={{ display: error.currentPassword && !currentPassword  ? 'block' : 'none' }}>{error.currentPassword ? error.currentPassword : ''}</div>
                    </div>
                </div>
                <div className='row' style={{ marginTop: '40px' }}>
                    <div className='col-md-3 col-12'>
                        <div className='d-flex flex-row align-items-center h-100'>
                            <label className='size16700'>New Password<span className='text-danger'>*</span></label>
                        </div>
                    </div>
                    <div className='col-md-9 col-12' style={{ position: 'relative' }}>
                        <div className="d-flex align-items-center">
                            <input
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                type={showNewpassword ? "text" : "password"}
                                className={`form-control custom-input hideIcon ${error.newPassword && !newPassword ? 'is-invalid' : ''}`}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    right: "23px"
                                }}
                                onClick={() => setShowNewpassword(!showNewpassword)}
                            >
                                {showNewpassword ? (
                                    <FiEye ></FiEye>
                                ) : (
                                    <FiEyeOff ></FiEyeOff>
                                )}
                            </span>
                        </div>
                        <div className="invalid-feedback" style={{ display: error.newPassword && !newPassword ? 'block' : 'none' }}>{error.newPassword ? error.newPassword : ''}</div>
                    </div>
                    <div className='col-md-3 col-12'></div>
                    <div className='col-md-9 col-12'>
                        <p className={`size14500 ${error.validate && !validatePassword(newPassword) ? 'text-danger' : ''}`} style={{ color: colors.grayColor }}>New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters</p>
                    </div>
                </div>
                <div className='row' style={{ marginTop: '40px' }}>
                    <div className='col-md-3 col-12'>
                        <div className='d-flex flex-row align-items-center h-100'>
                            <label className='size16700'>Confirm New Password<span className='text-danger'>*</span></label>
                        </div>
                    </div>
                    <div className='col-md-9 col-12' style={{ position: 'relative' }} >
                    <div className="d-flex align-items-center">
                        <input
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showRepassword ? "text" : "password"}
                            className={`form-control custom-input hideIcon ${(error.confirmPassword && !confirmPassword) || (error.notMatch && newPassword !== confirmPassword) ? 'is-invalid' : ''}`}
                        />
                        <span
                                        style={{
                                            position: "absolute",
                                            cursor: "pointer",
                                            right: "23px"
                                        }}
                                        onClick={() => setShowRepassword(!showRepassword)}
                                    >
                                        {showRepassword ? (
                                            <FiEye ></FiEye>
                                        ) : (
                                            <FiEyeOff ></FiEyeOff>
                                        )}
                                    </span>
                        </div>
                        <div className="invalid-feedback" style={{ display: (error.confirmPassword && !confirmPassword) || (error.notMatch && newPassword !== confirmPassword) ? 'block' : 'none' }}>{(error.confirmPassword && !confirmPassword) ? error.confirmPassword : (error.notMatch && newPassword !== confirmPassword) ? error.notMatch : ''}</div>
                    </div>
                </div>
            </div>
            <div className='col-12' style={{ marginTop: '46px' }}>
                <div className='d-flex justify-content-center'>
                    <PrimaryButton
                        title="Submit"
                        color='white'
                        onClick={handleChangePassword}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChangePassword