import React, { useEffect, useState } from "react";
import KeyMessage from "./appendKeyMessage";

const Content = (props) => {
    const {
        addKeyMessage,
        setAddKeyMessage,
        personalization,
        setPersonalization,
        submits
    } = props

    const [otherActive, setOtherActive] = useState()

    function handleClick() {
        addKeyMessage.push({
            keyMessage: '', 
            keyLanguage: '',
            channel: [
                { "type": "email", "isCheck": "no" },
                { "type": "sms", "isCheck": "no" },
                { "type": "app", "isCheck": "no" }
            ]
        })
        setAddKeyMessage([...addKeyMessage]);
    }

    const handleDynamicContent = (event, i, index) => {
        const contents = [...personalization];

        if (event.target.checked) {
            contents[index]['dynamicContents'][i].isCheck = 'yes';
            setPersonalization(contents);
        } else {
            contents[index]['dynamicContents'][i].isCheck = 'no';
            setPersonalization(contents);
        }
        if (event.target.id === 'Other') {
            setOtherActive(event.target.checked)
            if (!event.target.checked) {
                contents[index].additionalText = ''
            }
        }
    }

    const handleOtherMessage = (event, index) => {
        const contents = [...personalization];
        contents[index].additionalText = event.target.value;
        setPersonalization(contents);
    }

    const handleRemoveMessage = (index) => {
        addKeyMessage.splice( addKeyMessage.length - 1 , 1);
        setAddKeyMessage([...addKeyMessage]);
    }

    useEffect(()=> {
        if(personalization && personalization.length > 0){
            personalization.forEach(item => {
                let otherFind = item.dynamicContents.find(value => value.type === 'Other' && value.isCheck === 'yes' )
                if(otherFind){
                    setOtherActive(true)
                }
            });
        }
    }, [personalization])
    
    return (
        <div className="">
            <h2 className='size24700' style={{ marginBottom: '40px' }}>Content
            <p className='size14520 mb-0 mt-1'>*If your content features Spanish, please have your translation available when submitting this request</p>
            </h2>
            <div>
                <div>
                    <KeyMessage addKeyMessage={addKeyMessage} setAddKeyMessage={setAddKeyMessage} submits={submits} handleRemoveMessage={handleRemoveMessage}/>
                    <label
                        for="appendKeyMessage" class="form-label size14700" style={{ cursor: 'pointer', color: 'red', marginTop: '40px' }}
                        onClick={handleClick}>
                        +Add Key Message</label> &nbsp; &nbsp; 
                </div>
            </div>
            {
                personalization && personalization.length > 0 && personalization.map((item, index) => (
                    <div key={index}>
                        <div className="mt-4">
                            <label for="personalization" class="form-label size18700" style={{ marginBottom: '4px' }}>Personalization / Dynamic Content (please select all that apply)</label>
                            {
                                item.dynamicContents.length > 0 ? (
                                    <div className="row gx-0 gy-3 mt-16px">
                                        {
                                            item.dynamicContents.map((value, ind) => (
                                                <div class="form-check col-12 col-md-4">
                                                    <input
                                                        className='input-check'
                                                        type="checkbox"
                                                        value={value.type}
                                                        id={value.type}
                                                        checked={value.isCheck === 'yes' ? true : false}
                                                        onChange={(e) => handleDynamicContent(e, ind, index)} />
                                                    <label for={value.type} className="size16700 flexCheckDefault">
                                                        {value.type}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                        {
                            otherActive && (
                                <div style={{ marginTop: '26px' }}>
                                    <label for="floatingTextarea2" class="form-label size14700" style={{ marginBottom: '4px' }}>Other</label>
                                    <textarea onChange={(event) => handleOtherMessage(event, index)} value={item.additionalText} class="form-control custom-input" id="floatingTextarea2" style={{ height: '100px' }}></textarea>
                                </div>
                            )
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default Content;