import React, { useEffect, useState } from "react";
import UploadIcon from '../assets/images/icons/upload-icon.svg'
import CloseIcon from '../assets/images/icons/close-icon.svg'
import { sliceURL } from "../functions/sliceURL";
import BlockLoading from "../container/blockLoading/blockLoading";
import LanguageComponent from "./LanguageComponent";

const Design = (props) => {

    const {
        setStyleGuide,
        styleGuide,
        setSpecifyCampaign,
        specifyCampaign,
        setContentApplied,
        contentApplied,
        setHasCampaign,
        hasCampaign,
        setListPeople,
        listPeople,
        uploadeFile,
        validateFields,
        designAssetLeverageLanguage, setdesignAssetLeverageLanguage,
        contentDesignAssetLanguage, setcontentDesignAssetLanguage
    } = props;
    const { handleUploadFile,
        contentDesignAsset,
        setContentDesignAsset,
        designAssetLeverage,
        setdesignAssetLeverage,
        designOther, setdesignOther } = uploadeFile;

    const [campaignIdeaActive, setCampaignIdeaActive] = useState()
    const [fileSupport, setFileSupport] = useState({
        contentDesignAsset: "Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)",
        designAssetLeverage: "Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)"
    })

    const [newContentLoading, setNewContentLoading] = useState(false)
    const [existingContentLoading, setExistingContentLoading] = useState(false)
    const [uploadNull, setUploadNull] = useState("")
    const [newContentFile, setNewContentFile] = useState([])
    const [existingContentFile, setExistingContentFile] = useState([])

    const handleHasCampaignIdea = (e) => {
        const { value, id } = e.target
        setCampaignIdeaActive(id)
        setHasCampaign([{
            "type": value,
            "isCheck": id
        }])
    }

    useEffect(()=> {
        if(hasCampaign){
            if(hasCampaign.length > 0) {
                setCampaignIdeaActive(hasCampaign[0].isCheck)
            }
        }
    }, [hasCampaign])

    const toBase64 = (file, type, id, i) => new Promise((resolve, reject) => {
        const fileName = file.name;
        document.getElementById(id).innerHTML = fileName;
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            handleUploadFile(fileName, base64String, type, setNewContentLoading, setExistingContentLoading, i);
        };
        reader.readAsDataURL(file);
    });

    const handleFileChange = async (event, type, i) => {
        const { value, id } = event.target;
        if (id === 'contentDesignAsset') {
            if(contentDesignAsset[i] && contentDesignAsset[i].newContentFile && contentDesignAsset[i].newContentFile.find(element => element === value)){
                return false
            }else {
                fileSupport["contentDesignAsset"] = value
            }
            newContentFile.push(value)
            setNewContentFile([...newContentFile])
            if(!contentDesignAsset[i].newContentFile) {
                contentDesignAsset[i].newContentFile = []
            }
            contentDesignAsset[i].newContentFile.push(value)
            setContentDesignAsset([...contentDesignAsset])
        }
        if (id === 'designAssetLeverage') {
            if(designAssetLeverage[i] && designAssetLeverage[i].existingContentFile && designAssetLeverage[i].existingContentFile && designAssetLeverage[i].existingContentFile.find(element => element === value)){
                return false
            }else {
                fileSupport["designAssetLeverage"] = value
            }
            existingContentFile.push(value)
            setExistingContentFile([...existingContentFile])
            if(!designAssetLeverage[i].existingContentFile) {
                designAssetLeverage[i].existingContentFile = []
            }
            designAssetLeverage[i].existingContentFile.splice(i, 1)
            setdesignAssetLeverage([...designAssetLeverage])
        }
        setFileSupport({ ...fileSupport })
        const file = event.target.files[0];
        await toBase64(file, type, id, i)
    }
    
    return (
        <div className="">
            <h2 className='size24700' style={{ marginBottom: '30px' }}>Design</h2>
            <div style={{ marginBottom: '30px' }}>
                <label for="styleGuide" class="form-label size18700" style={{ marginBottom: '4px' }}>Style Guide Approach</label>
                <textarea class="form-control custom-input" id="floatingTextarea2" style={{ height: '100px' }} value={styleGuide} onChange={(e) => setStyleGuide(e.target.value)} placeholder="e.g. Do the Amazing campaign or corporate style"></textarea>
            </div>
            <div className="col-12" style={{ marginBottom: '24px' }}>
                <div className="d-flex flex-column w-100">
                    <div className='d-flex flex-row align-items-center mb-2'>
                        <label for="newContent" class="form-label size18700" style={{ marginTop: '6px' }}>New Content / Design Assets</label>
                    </div>

                    { contentDesignAsset && contentDesignAsset.length > 0 && contentDesignAsset.map((it, i) => (<div className="w-100">
                        {i > 0 &&
                                    contentDesignAsset.length > 1 ? <div
                                    for="appendKeyMessage" class="form-label size14700 mb-0 mt-2 d-flex justify-content-end" style={{ cursor: 'pointer', color: 'red'}}
                                    
                                    > <span onClick={() => {
                                        contentDesignAsset.splice(i, 1)
                                        setContentDesignAsset([...contentDesignAsset])
                                    }} style={{cursor: "pointer"}} class="form-label size14700 mb-0" ><img alt="" src={CloseIcon} style={{ width: '15px' }} /></span> </div>
                                : ''}
                        <div className="d-flex upload-file-body-cus mt-2- align-items-center">
                            <LanguageComponent
                                keyLanguage={it.keyLanguage}
                                setKeyLanguage={(value) => {
                                    it.keyLanguage = value
                                    setContentDesignAsset([...contentDesignAsset])
                                }}
                                marginLeft="0px"
                            />

                            <div className="upload-file-body upload-file-body-border-none w-100" style={{cursor: it.keyLanguage ? "pointer" : "pointer"}}>
                                <div className="d-flex- flex-row- align-items-center justify-content-between w-100">
                                    <div className="d-flex flex-row align-items-center">
                                        <img src={UploadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                        <p className="size16700 mb-0">Upload file</p>
                                    </div>
                                    {it.keyLanguage && <input id="contentDesignAsset" type="file" value={uploadNull} onChange={(e) => it.keyLanguage && handleFileChange(e, 'contentDesignAsset', i)} multiple placeholder="CSLPLASMA.pdf" />}
                                    <div className="d-flex flex-column">
                                        {
                                            it.files && it.files.length > 0 ? it.files.map((item, index) => (
                                                <div className="d-flex flex-row align-items-center justify-content-between" style={{ position: 'relative', zIndex: '10' }}>
                                                    <a target="_blank" href={item.url} className="mb-0 design-upload">{sliceURL(item.url).slice(14)}</a>
                                                    <div onClick={() => {
                                                        it.files.splice(index, 1)
                                                        setContentDesignAsset([...contentDesignAsset])
                                                        // newContentFile.splice(index, 1)
                                                        // setNewContentFile([...newContentFile])
                                                        setUploadNull("")
                                                    }} style={{ marginLeft: '10px' }} className="transparent-button">
                                                        <img alt="" src={CloseIcon} style={{ width: '15px' }} />
                                                    </div>
                                                </div>
                                            )) : <p className="size14600 mb-0" style={{ color: '#8B8B8B' }}>Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                it.newContentLoading ? <BlockLoading /> : null
                            }
                        </div>
                    </div>))}
                </div>

                <div>
                    <div>
                        <label
                            for="appendKeyMessage" class="form-label size14700"
                            style={{
                                cursor: 'pointer', color: 'red',
                                // marginTop: '40px'
                            }}
                            onClick={() => {
                                setContentDesignAsset([...contentDesignAsset, {
                                    keyLanguage: '',
                                    files: []
                                }])
                            }}
                        >
                            +Add</label> &nbsp; &nbsp;
                    </div>
                </div>

            </div>
            <div style={{ marginBottom: '40px' }}>
                <div class="form-check col-4 p-1">
                    <input className='input-check' type="checkbox" checked={designOther && designOther.other === 'yes'} onChange={(e) => { e.target.checked ? setdesignOther({ ...designOther, other: 'yes' }) : setdesignOther({ ...designOther, other: 'no', additionalText: '' }) }} value="" id="flexCheckDefault" />
                    <label for="flexCheckDefault" className="flexCheckDefault size16700">
                        Other
                    </label>
                </div>
                {
                    designOther && designOther.other === 'yes' ? (
                        <div style={{ marginTop: '26px' }}>
                            <label for="campaignKpis" class="form-label size14700" style={{ marginBottom: '4px' }}>Other</label>
                            <textarea class={`form-control custom-input`} id="floatingTextarea2" value={designOther.additionalText} onChange={(e) => setdesignOther({ ...designOther, additionalText: e.target.value })} style={{ height: '100px' }}></textarea>
                        </div>
                    ) : null
                }
            </div>
            <div className="col-12" style={{ marginBottom: '30px' }}>
                <div className="d-flex flex-column w-100">
                    <div className='d-flex flex-row align-items-center mb-2'>
                        <label for="exitingContent" class="form-label size18700" style={{ marginTop: '6px' }}>Existing Content / Design Assets to be Leveraged</label>
                    </div>

                    {
                        designAssetLeverage.map((it, i) => (
                            <div className="w-100">
                                 {i > 0 &&
                                    designAssetLeverage.length > 1 ? <div
                                    for="appendKeyMessage" class="form-label size14700 mb-0 mt-2 d-flex justify-content-end" style={{ cursor: 'pointer', color: 'red'}}
                                    
                                    > <span onClick={() => {
                                        designAssetLeverage.splice(i, 1)
                                        setdesignAssetLeverage([...designAssetLeverage])
                                    }} style={{cursor: "pointer"}} class="form-label size14700 mb-0" ><img alt="" src={CloseIcon} style={{ width: '15px' }} /></span> </div>
                                : ''}
                                <div className="d-flex upload-file-body-cus mt-2- align-items-center">
                                    <LanguageComponent
                                        keyLanguage={it.keyLanguage}
                                        setKeyLanguage={(value) => {
                                            it.keyLanguage = value
                                            setContentDesignAsset([...contentDesignAsset])
                                        }}
                                        marginLeft="0px"
                                    />

                                    <div className="upload-file-body upload-file-body-border-none w-100" style={{cursor: it.keyLanguage ? "pointer" : "pointer"}}>
                                        <div className="d-flex- flex-row- align-items-center justify-content-between w-100">
                                            <div className="d-flex flex-row align-items-center">
                                                <img src={UploadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                <p className="size16700 mb-0">Upload file</p>
                                            </div>
                                            {it.keyLanguage && <input id="designAssetLeverage" type="file" value={uploadNull} onChange={(e) => handleFileChange(e, 'designAssetLeverage', i)} multiple placeholder="CSLPLASMA.pdf" />}
                                            <div className="d-flex flex-column">
                                                {
                                                    it.files && it.files.length > 0 ? it.files.map((item, index) => (
                                                        <div className="d-flex flex-row align-items-center justify-content-between" style={{ position: 'relative', zIndex: '10' }}>
                                                            <a target="_blank" href={item.url} className="mb-0 design-upload">{sliceURL(item.url).slice(14)}</a>
                                                            <div onClick={() => {
                                                                it.files.splice(index, 1)
                                                                setdesignAssetLeverage([...designAssetLeverage])
                                                                existingContentFile.splice(index, 1)
                                                                setExistingContentFile([...existingContentFile])
                                                                setUploadNull("")
                                                            }} style={{ marginLeft: '10px' }} className="transparent-button">
                                                                <img alt="" src={CloseIcon} style={{ width: '15px' }} />
                                                            </div>
                                                        </div>
                                                    )) : <p className="size14600 mb-0" style={{ color: '#8B8B8B' }}>Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        it.existingContentLoading ? <BlockLoading /> : null
                                    }
                                </div>
                            </div>
                        ))
                    }
                    
                </div>

                <div>
                    <div>
                        <label
                            for="appendKeyMessage" class="form-label size14700"
                            style={{
                                cursor: 'pointer', color: 'red',
                                // marginTop: '40px'
                            }}
                        onClick={() => {
                            setdesignAssetLeverage([...designAssetLeverage, {
                                keyLanguage: '',
                                files: []
                            }])
                        }}
                        >
                            +Add</label> &nbsp; &nbsp;
                    </div>
                </div>

            </div>
            <div style={{ marginBottom: '30px' }}>
                <label for="currentlyAssets" class="form-label size14700" style={{ marginBottom: '4px' }}>Please specify the campaign that currently uses these assets</label>
                <textarea class="form-control custom-input" id="floatingTextarea2" value={specifyCampaign} onChange={(e) => setSpecifyCampaign(e.target.value)} style={{ height: '100px' }} ></textarea>
            </div>
            <div style={{ marginBottom: '30px' }}>
                <label for="media" class="form-label size18700" style={{ marginBottom: '4px' }}>Where will the content be applied (e.g. video, email, social media, etc.)?</label>
                <textarea class="form-control custom-input" id="floatingTextarea2" value={contentApplied} onChange={(e) => setContentApplied(e.target.value)} style={{ height: '100px' }} placeholder="e.g. video, email, social media, etc."></textarea>
            </div>
            <div style={{ marginBottom: '30px' }}>
                <label for="teamLegal" class="form-label size18700" style={{ marginBottom: '16px' }}>Has the campaign idea and proposed illustration been approved by the marketing team and legal?*</label>
                <div className="d-flex flex-row">
                    <div class="form-check" style={{ marginRight: '80px' }}>
                        <input
                            className='input-check'
                            onChange={handleHasCampaignIdea}
                            type="checkbox"
                            value="Yes"
                            id="yes"
                            checked={campaignIdeaActive === 'yes' ? true : false}
                        />
                        <label for="" className="flexCheckDefault size16700">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            className='input-check'
                            onChange={handleHasCampaignIdea}
                            type="checkbox"
                            value="No"
                            id="no"
                            checked={campaignIdeaActive === 'no' ? true : false}
                        />
                        <label for="" className="flexCheckDefault size16700">
                            No
                        </label>
                    </div>
                </div>
                {
                    validateFields.hasCampaign && !hasCampaign ? (
                        <p className='text-danger size12400 mb-0 mt-2'>{validateFields.hasCampaign}</p>
                    ) : ''
                }
            </div>
            {campaignIdeaActive === 'yes' ? <div>
                <label for="listCampaign" class="form-label size14700" style={{ marginBottom: '4px' }}>Please list the people who have approved this campaign*</label>
                <textarea class={`form-control custom-input ${validateFields.listPeople && !listPeople ? 'is-invalid' : ''}`} id="floatingTextarea2" value={listPeople} onChange={(e) => setListPeople(e.target.value)} style={{ height: '100px' }} ></textarea>
                <div className="invalid-feedback">{validateFields.listPeople && validateFields.listPeople}</div>
            </div> : null}
        </div>
    )
}

export default Design;