import React, { useState, setState, state, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DonorType from '../components/DonorType';
import SelectDonationHistory from '../components/SelectDonationHistory';
import CheckedYesBox from '../components/CheckedYesBox';
import AddKeyMessage from '../components/AddKeyMessage';
import { BrowserRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faUpload } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Axios } from 'axios';

import { isCompositeComponentWithType } from 'react-dom/test-utils';
import useForm from '../useForm';
// import DonationHistoryCalendar from './DonationHistoryCalendar';
import DatePickerCalendar from '../components/DatePickerCalendar';
import CheckedOtherCommentBox from '../components/CheckedOtherCommentBox';
import ReCaptcha from '../config/ReCaptcha';
import UploadFiles from '../components/UploadFiles';
import AdditionalCommentBox from '../components/AdditionalCommentBox';
import LocationCenter from '../components/LocationCenter';
import LoginForm from '../components/LoginForm';
import { CalendarCSL } from '../container/calendar/CalendarCSL';
import CalendarIcon from '../assets/images/icons/calendar-icon.svg'
import CampaignRun from '../container/campaignRun';
import KeyMessage from '../container/keyMessage';
import API from '../utils/request'
import { INTAKE_FORM, UPLOAD_FILE } from '../api/URLs';

import "../styles/intakeForm.css"
import StakeHolder from '../components/stakeholder/stakeHolder';
import CampaignDetails from '../components/campaignDetails/campaignDetails';
import Audience from '../components/audience/audience';
import Content from '../components/Content';
import Design from '../components/Design';
import CampaignMandatories from '../components/CampaignMandatories';
import CampaignKPIs from '../components/CampaignKPIs';
import { connect } from 'react-redux';
import { formatDateAPI, formatDateAPI2 } from '../functions/format/dateFormat';
import LoadingOverlay from '../container/loading/LoadingOverlay';
import { validationSubmitForm } from '../functions/validation';
import MessageModalMain from '../container/modal/MessageModalMain';
import IntakeForm from './intakeForm';
import IntakeFormV2 from './IntakeFormV2';

const IntakeFormPage = (props) => {
    const { user } = props;
    
    const messageModalRef = useRef('rememberMe');
    const [loading, setLoading] = useState(false)
    const [disabledButton, setDisabledButton] = useState(true)
    const [isSubmit, setisSubmit] = useState(false)

    return (
        <div className="main-form d-flex flex-column align-items-center">
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
            <IntakeFormV2 {...props} />
            {/* <IntakeForm {...props} /> */}
        </div>
    )
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = state => ({
    user: state.authReducer.user,
    token: state.authReducer.token
})

export default (connect(mapStateToProps, mapDispatchToProps)(IntakeFormPage));