import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import '../styles/editFormIntake.css';
import API from '../utils/request'
import LoadingOverlay from "../container/loading/LoadingOverlay";
import { COMPAIGN_BY_ID } from "../api/URLs";
import SubmitViewIntakeForm from "./submitViewIntakeForm";
import SubmitViewIntakeFormV2 from "./submitViewIntakeFormV2";

const ViewForm = (props) => {
    const [dataNew, setDataNew] = useState(null)
    const [loading, setLoading] = useState(true)
    const [getCampaignId, setGetCampaignId] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataEnity, setDataEnity] = useState(false);

    useEffect(() => {
        if (searchParams.get('cam')) {
            setGetCampaignId(searchParams.get('cam'))
            fetchViewByID(searchParams.get('cam'));
        } else {
            console.log("Fail!")
        }
    }, [])


    const fetchViewByID = (id) => {
        setLoading(true)
        var params = {
            "campaignId": id
        }

        API.post(COMPAIGN_BY_ID, params)
            .then((res) => {
                setLoading(false)
                let data = res && res.data;
                setDataNew(data)

            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
                setDataEnity(true);
            })
    }

    return (
        <>
            {loading ? <LoadingOverlay /> : null}
            {
                dataNew && dataNew.isVersion === 'v2' ? <SubmitViewIntakeFormV2 loading={loading} dataNew={dataNew} /> : dataNew && <SubmitViewIntakeForm loading={loading} dataNew={dataNew} />
            }
        </>
    )
}

export default ViewForm;