import moment from "moment";
import { useRef, useState } from "react";
import CalendarIcon from '../../assets/images/icons/calendar-icon.svg'
import { CalendarCSL } from '../../container/calendar/CalendarCSL'
// import dayjs from "dayjs";

const CalendarComonent = ({
    dateFrom, setDateFrom,
    dateTo, setDateTo,
    dateRangeError, setDateRangeError,
    dateRangeErrorEnd, setDateRangeErrorEnd,
    id
}) => {
    
    const [error, setError] = useState({});
    // const [dateRangeErrorEnd, setDateRangeErrorEnd] = useState(null)
    // const [dateRangeError, setDateRangeError] = useState(null)
    

    const refOne = useRef(null)
    
    // const [dateFrom, setDateFrom] = useState()
    // const [dateTo, setDateTo] = useState()
    
    const [toActive, setToActive] = useState(false)
    const [fromActive, setFromActive] = useState(false)

    const changeDateTo = (date) => {
        setDateTo(date)
    }
    const changeDateFrom = (date) => {
        setDateFrom(date)
    }


    return <div id={id}>
        {/* <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Date of issue discovered*</label> */}
        <div className='d-flex flex-row align-items-center justify-content-between'>
            <div>
                <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Date of issue discovered*</label>
                <div onClick={() => { setFromActive(!fromActive); setToActive(false); }} className={`calendar-box ${dateRangeError && (!dateFrom) ? "error-field" : ""}`} ref={refOne}>
                    <p>{dateFrom ? (moment(dateFrom).format('MM / DD / YYYY')) : 'Select date'}</p>
                    <img onClick={() => { setFromActive(!fromActive); setToActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                </div>
                {
                    dateRangeError && (!dateFrom) ? (
                        <p style={{ position: 'absolute' }} className='text-danger size12400 mb-0 mt-2'>{dateRangeError}</p>
                    ) : ''
                }
            </div>
            <div>
            <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}></label>
            <div style={{ width: '10px', height: '1px', backgroundColor: 'black', marginLeft: '16px', marginRight: '16px' }}></div>
            </div>
            <div>
                <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Due date for desired resolution*</label>
                <div onClick={() => { setToActive(!toActive); setFromActive(false); }} className={`calendar-box ${dateRangeErrorEnd && (!dateTo) ? "error-field" : ""}`} ref={refOne}>
                    <p>{dateTo ? (moment(dateTo).format('MM / DD / YYYY')) : 'Select date'}</p>
                    <img onClick={() => { setToActive(!toActive); setFromActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                </div>
                {
                    dateRangeErrorEnd && (!dateTo) ? (
                        <p style={{ position: 'absolute' }} className='text-danger size12400 mb-0 mt-2'>{dateRangeErrorEnd}</p>
                    ) : ''
                }
            </div>
        </div>
        {/* {
            dateRangeError && (!dateFrom || !dateTo) ? (
                <p className='text-danger size12400 mb-0 mt-2'>{dateRangeError}</p>
            ) : ''
        } */}
        <div ref={refOne}>
            <CalendarCSL float={'left'} activeCalendar={fromActive} setactiveCalendar={setFromActive} changeDate={changeDateFrom} date={dateFrom} maxDate={true} handleActiveCalendar={() => { setFromActive(!fromActive); setToActive(false); }} />
            <CalendarCSL float={'right'} activeCalendar={toActive} setactiveCalendar={setToActive} changeDate={changeDateTo} date={dateTo} maxDate={true} handleActiveCalendar={() => { setToActive(!toActive); setFromActive(false); }} />
        </div>
    </div>
}

export default CalendarComonent;