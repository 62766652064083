import React from 'react';

import LoginForm from './components/LoginForm';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import SubmitViewIntakeForm from './pages/submitViewIntakeForm';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Layout from './layout/layout';
import { storageKeys } from './constants/storage';
import ChangePassword from './pages/changePassword';
import ResetPassword from './pages/resetPassword';
import PageNotFound from './pages/pageNotFound'
import SubmitedFormList from './pages/sumbitedFormList';
import IntakeFormEdit from './pages/intakeFormEdit';
import SumbitedDraftFormList from './pages/sumbitedDraftFormList';
import ViewForm from './pages/viewFrom';
import IntakeFormPage from './pages/IntakeFormPage';
import IntakeFormEditV2 from './pages/intakeFormEditV2';

const tokens = localStorage.getItem(storageKeys.TOKEN);
class AppRouter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true
        }
    }
    
    componentDidMount(){
        const url = new URL(window.location.href);
        const searchParams = url.searchParams;
        if (!tokens && searchParams.get('cam')) {
            localStorage.setItem('cam', searchParams.get('cam'));
        } else {
            localStorage.removeItem('cam')
        }

    }

    render() {
        return (
            <Router>
                <Layout>
                    <Routes>
                        <Route path='/' element={tokens ? <IntakeFormPage {...this.props} /> : <Navigate to="/login" />} />
                        <Route path='/viewFormIntake' element={tokens ? <ViewForm {...this.props} /> : <Navigate to="/login" />} />
                        <Route path='/editFormIntake' element={tokens ? <IntakeFormEditV2 {...this.props} /> : <Navigate to="/login" />} />
                        <Route path='/change-password' element={tokens ? <ChangePassword {...this.props} /> : <Navigate to="/login" />} />
                        <Route path='/submitFormList' element={tokens ? <SubmitedFormList {...this.props} /> : <Navigate to="/login" />} />
                        <Route path='/submitDraftFormList' element={tokens ? <SumbitedDraftFormList {...this.props} /> : <Navigate to="/login" />} />
                        <Route path='/login' element={!tokens ? <LoginForm {...this.props} /> : <Navigate to="/" />} />
                        <Route path='/set-new-password' element={<ResetPassword {...this.props} />} />
                        <Route path="*" element={<PageNotFound {...this.props} /> } />
                    </Routes>
                </Layout> 
            </Router>
        );
    }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = state => ({
    user: state.authReducer.user,
    token: state.authReducer.token
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AppRouter));
